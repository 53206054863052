import React, { Component } from "react";
import RemoteTable from "../components/RemoteTable";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";
import Worksheet from "../components/Worksheet";
import XLSX from "xlsx";
import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import RemoteAll from "../components/RemoteAll";

import { Create, CreateSchedule } from "../modules/UploadRawModule";
const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls", "csv"]
  .map((x) => `.${x}`)
  .join(",");

class Tags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      submittedSchedule: false,
      showModal: false,
      showModalDailySpotPrice: false,
      loading: false,
      readOnly: false,
      currencyreadOnly: false,
      unitreadOnly: false,
      isClearable: true,
      collection: {},
      list_datatable: [],
      errors: {},
      errors_edit: {},
      id: null,
      requester: null,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      search: "",
      production_grade: null,
      read_type: "",
      formula: null,
      select_items_material: [],
      details: [],
      selectedFile: null,
      worksheet: [],
      error_message_filename: null,
      spinner: false,
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    this.setState({
      department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.nama_department
        : null,
      requester: user.username,
      requester_name: user.details.hris_org_tree.current_person.person_name,
    });

    const { sortBy, sortOrder, page, sizePerPage, query_material } = this.state;
    const query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }

    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        requester: user.username,
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };

  onChangeDetail = (any, idx) => {
    const details = this.state.details;
    let temp = [...details];

    temp[any.target.name] = any.target.value;
    this.setState({
      details: [...temp],
    });
  };

  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.label,
        [name + "_code"]: newValue.value,
      });
      if (name == "material") {
        this.setState({
          isValidMaterial: true,
        });
      } else if (name == "customer") {
        this.setState({
          isValidCustomer: true,
        });
      } else if (name == "customer_create") {
        this.setState({
          isValidCustomerCreate: true,
        });
      }
    } else {
      this.setState({
        [name]: null,
        [name + "_code"]: null,
      });
      if (name == "material") {
        this.setState({
          isValidMaterial: false,
        });
      } else if (name == "customer") {
        this.setState({
          isValidCustomer: false,
        });
      } else if (name == "material_create") {
        this.setState({
          isValidMaterialCreate: false,
        });
      } else if (name == "customer_create") {
        this.setState({
          isValidCustomerCreate: false,
        });
      }
    }
  };

  //date format on datatable
  GetDateFormat = (cell, row) => {
    const date_formatted = dateFormat(new Date(row.updated_at), "dd mmmm yyyy");
    return <div>{date_formatted}</div>;
  };
  //action button
  GetActionFormat = (cell, row) => {
    const { isEdit, isDelete } = this.state;
    return (
      <div>
        {isEdit && (
          <button
            key={row._id}
            type="button"
            className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            onClick={() => this.toDetail(row)}
          >
            <i className="fas fa-edit" key={row._id}></i>
          </button>
        )}

        <button
          key={row._id}
          type="button"
          className="btn btn-success btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => this.toView(row)}
        >
          <i className="fas fa-eye" key={row._id}></i>
        </button>

        {isDelete && (
          <button
            type="button"
            className="btn btn-danger btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            data-id={row._id}
            onClick={() => this.deleteData(row._id)}
          >
            <i className="fas fa-trash"></i>
          </button>
        )}
      </div>
    );
  };

  //validate input
  validateForm = () => {
    let { tag_name, details, errors } = this.state;

    if (!tag_name) {
      errors.tag_name = "This is field is required";
    } else {
      delete errors.tag_name;
    }

    if (details.length > 0) {
      details.forEach((item, idx) => {
        if (item == "") {
          errors[`detail_${idx}`] = "This is field is required";
        } else {
          delete errors[`detail_${idx}`];
        }
      });
    }

    return errors;
  };
  //validate input
  validateFormEdit = () => {
    let { tag_name, details, errors_edit } = this.state;

    if (!tag_name) {
      errors_edit.tag_name = "This is field is required";
    } else {
      delete errors_edit.tag_name;
    }

    if (details.length > 0) {
      details.forEach((item, idx) => {
        if (item == "") {
          errors_edit[`detail_${idx}`] = "This is field is required";
        } else {
          delete errors_edit[`detail_${idx}`];
        }
      });
    }

    return errors_edit;
  };
  validateFormCreate = (production_grade) => {
    let errors = this.state.errors_create;
    if (!production_grade) {
      errors.production_grade = "This is field required";
      this.setState({
        isValidProductionGrade: false,
      });
    }

    return errors;
  };

  validateSearch = (filter_by, search) => {
    let errors = this.state.errors_search;
    if (!filter_by) {
      errors.filter_by = "This is field required";
    }
    if (!search) {
      errors.search = "This is field required";
    }

    return errors;
  };
  //get data from backend
  reloadDataBackend = () => {
    const { worksheet, submitted, submittedSchedule } = this.state;
    this.form.reset();
    this.formSchedule.reset();
    this.setState({
      worksheet: [],
      submitted: false,
      submittedSchedule: false,
      selectedFile: null,
      excel: null,
    });
  };

  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const { history } = this.props;
    const {
      requester,
      department,
      excel,
      periode,
      selectedFile,
      worksheet,
      errors,
    } = this.state;
    if (!this.validateForm(selectedFile, periode, worksheet)) {
      return;
    }
    let collection = {
      requester: requester,
      department: department,
      excel: excel,
    };

    const size = new TextEncoder().encode(JSON.stringify(collection)).length;
    const kiloBytes = size / 1024;
    if (kiloBytes > 2000) {
      Swal.fire("Error", "Payload to large", "error");
      return;
    }
    if (requester && department && excel) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          Create(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-bom-powder-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                //read data exchange rate on backend
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-bom-powder-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  handleSubmit2 = (e) => {
    this.setState({ submittedSchedule: true });
    const { history } = this.props;
    const { requester, department, selectedFile } = this.state;
    const data = new FormData();

    if (selectedFile) {
      for (var x = 0; x < selectedFile.length; x++) {
        data.append("upload_file", selectedFile[x]);
      }
    }
    data.append("requester", requester);
    data.append("department", department);
    if (selectedFile) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.setState({
            loading: true,
          });
          //send to backend
          CreateSchedule(data)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {});
              this.reloadDataBackend();
              this.setState({
                loading: false,
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-failed",
              });

              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  onChangeHandlerFileExcel = (name, event) => {
    const files = event.target.files;
    this.setState({
      selectedFile: files,
    });
  };

  onChangeHandlerFile = (event) => {
    let file_size = event.target.files[0].size;
    if (file_size >= 1000000) {
      Swal.fire("Warning", "Max File Size is 1MB", "error");
      return;
    }
    let errors = this.state.errors;
    errors.filename = null;
    this.setState({
      selectedFile: event.target.files,
      loading: true,
      worksheet_name: true,
      error_message_filename: null,
      errors: errors,
      spinner: true,
    });
    const files = event.target.files;
    if (files && files[0]) {
      this.handleFile(files[0]);
      let errors = this.state.errors;
      errors.worksheet = null;
      this.setState({
        errors: errors,
      });
    }
    this.setState({
      loading: false,
    });
  };
  checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = "";
    // list allow mime type
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "text/csv",
    ];
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is not a supported format\n";
      }
    }
    if (err !== "") {
      // if message not same old that mean has error
      this.setState({ error_message_filename: err });
      event.target.value = null; // discard selected file
      return false;
    }
    return true;
  };
  handleFile = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      let worksheet = [];
      wb.SheetNames.map((e, i) => {
        const wsname = wb.SheetNames[i];
        if (wsname == "Rawdata Utama") {
          const result_worksheet = this.setting_worksheet(
            wb.Sheets[wsname],
            wsname
          );
          if (result_worksheet) worksheet.push(result_worksheet);
        }
      });
      const excel = {
        worksheet: worksheet,
        filename: file.name,
      };
      this.setState({
        worksheet: worksheet,
        loading: false,
        spinner: false,
        excel: excel,
      });
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };
  exists_only = (data, cols, start_row = 0, allowed_cols = []) => {
    let header = [];
    let detail = [];
    let clone_data = [...data];

    if (clone_data) {
      clone_data.map((r, i) => {
        let z = [...r];
        let splice = z.splice(0, 2);
        if (i === start_row) {
          let header_concat = splice.concat(z);
          header.push(header_concat);
        }
        //
        let cleanArray = z.filter(function (el) {
          return true && el != null && el != "";
        });

        if (i !== start_row && cleanArray.length > 1) {
          let array_concat = splice.concat(z);
          detail.push(array_concat);
        }
      });
    }
    return { header: header, detail: detail };
  };
  //setting worksheet
  setting_worksheet = (ws, wsname) => {
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false });
    if (!data) return;
    /* Update state */
    if (!ws["!ref"]) return;
    const cols = this.make_cols(ws["!ref"]);
    const result = this.exists_only(data, cols);
    const custom_cols = this.make_custom_cols(result.header);

    return {
      worksheet: wsname,
      data: result.detail,
      cols: custom_cols,
    };
  };
  /* generate an array of column objects */
  make_cols = (refstr) => {
    let o = [],
      C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i)
      o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
  };
  make_custom_cols = (obj) => {
    let o = [];
    if (obj) {
      obj[0].map((b, d) => {
        o.push({ name: b, key: d });
      });
    }
    return o;
  };

  render() {
    let {
      errors,
      submitted,
      submittedSchedule,
      loading,
      isValidMaterial,
      isCreate,
      error_message_filename,
      worksheet,
      spinner,
    } = this.state;
    const customStylesMaterial = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterial
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterial
            ? "#ddd"
            : "green",
        },
      }),
    };
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Import</h3>
                        </div>
                        <div className="card-body">
                          <form
                            autoComplete="off"
                            className={
                              submitted
                                ? "needs-validation was-validated"
                                : "needs-validation"
                            }
                            noValidate
                            ref={(form) => (this.form = form)}
                          >
                            <Row>
                              <Col xs={12} md={2}></Col>
                              <Col xs={12} md={4}></Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={12}>
                                <ul>
                                  <li>
                                    <span style={{ color: "red" }}>
                                      Max file size 1MB
                                    </span>
                                  </li>
                                  <li>
                                    <span style={{ color: "red" }}>
                                      Sheet name must be <b>Rawdata Utama</b>
                                    </span>
                                  </li>
                                </ul>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="form-group row">
                                  <div className="col-md-3 col-xs-12">
                                    <label htmlFor="file">
                                      Choose File (Excel) *
                                    </label>
                                  </div>
                                  <div className="col-md-9 col-xs-12">
                                    <input
                                      type="file"
                                      className="form-control form-control-sm"
                                      name="file"
                                      placeholder="File"
                                      onChange={this.onChangeHandlerFile}
                                      accept={SheetJSFT}
                                      required
                                    />

                                    {error_message_filename && (
                                      <em
                                        className="error-message"
                                        style={{
                                          fontSize: "80%",
                                        }}
                                      >
                                        {error_message_filename}
                                      </em>
                                    )}

                                    {errors.worksheet && (
                                      <em
                                        className="error-message"
                                        style={{
                                          fontSize: "80%",
                                        }}
                                      >
                                        {errors.worksheet}
                                      </em>
                                    )}
                                    {errors.filename && (
                                      <em
                                        className="error-message"
                                        style={{
                                          fontSize: "80%",
                                        }}
                                      >
                                        {errors.filename}
                                      </em>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={12}>
                                <Worksheet worksheet={worksheet} />
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              {spinner ? (
                                <div className="d-flex align-self-center m-5">
                                  <Spinner
                                    className="text-center"
                                    animation="border"
                                    variant="primary"
                                    size="lg"
                                  />
                                  <h5 className="ml-2">Loading Data</h5>
                                </div>
                              ) : (
                                <Col xs={12} md={6}>
                                  <Button
                                    variant="success"
                                    onClick={this.handleSubmit}
                                    className="float-right  "
                                  >
                                    <i className="fas fa-save" /> Submit
                                  </Button>
                                </Col>
                              )}
                            </Row>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Import Bulk </h3>
                        </div>
                        <div className="card-body">
                          <form
                            autoComplete="off"
                            className={
                              submittedSchedule
                                ? "needs-validation was-validated"
                                : "needs-validation"
                            }
                            noValidate
                            ref={(formSchedule) =>
                              (this.formSchedule = formSchedule)
                            }
                          >
                            <Row>
                              <Col xs={12} md={12}>
                                <ul>
                                  <li>
                                    <span style={{ color: "red" }}>
                                      Cron runs every hour
                                    </span>
                                  </li>
                                  <li>
                                    <span style={{ color: "red" }}>
                                      Sheet name must be <b>Rawdata Utama</b>
                                    </span>
                                  </li>
                                </ul>
                              </Col>
                              <Col xs={12} md={4}></Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={6}>
                                <div className="form-group row">
                                  <div className="col-md-3 col-xs-12">
                                    <label htmlFor="file">
                                      Choose File (Excel) *
                                    </label>
                                  </div>
                                  <div className="col-md-9 col-xs-12">
                                    <input
                                      type="file"
                                      className="form-control form-control-sm"
                                      onChange={this.onChangeHandlerFileExcel.bind(
                                        this,
                                        "file2"
                                      )}
                                      name="file2"
                                      placeholder="File"
                                      accept={SheetJSFT}
                                      required
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col xs={12} md={6}>
                                <Button
                                  variant="success"
                                  onClick={this.handleSubmit2}
                                  className="float-right  "
                                >
                                  <i className="fas fa-save" /> Submit
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(Tags);
