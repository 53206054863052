import axios from "axios";
import authHeader from "./auth-header";
import refreshToken from "./refresh-token";
import jwt from "jsonwebtoken";
import { Cookies } from "react-cookie";

const API_URL = process.env.REACT_APP_AUTH;

const API_URL_DEFAULT = process.env.REACT_APP_API;
const private_key = process.env.REACT_APP_PRIVATE_KEY;

const cookies = new Cookies();

class AuthService {
  goONELNKHomapage() {
    return localStorage.clear();
  }
  loginPhone(payload) {
    return axios
      .post(`${API_URL}phone`, payload, { headers: authHeader() })
      .then(async (response) => {
        if (response.data.accessToken) {
          const verifyToken = jwt.verify(
            response.data.accessToken,
            private_key
          );
          localStorage.setItem("accessToken", JSON.stringify(response.data));
          localStorage.setItem("user", JSON.stringify(verifyToken));
          localStorage.setItem("photo", JSON.stringify(response.data.photo));
        }

        return response;
      });
  }
  login(username, password) {
    return axios
      .post(
        API_URL + "signin",
        { username, password },
        { headers: authHeader() }
      )
      .then((response) => {
        if (response.data.accessToken) {
          const verifyToken = jwt.verify(
            response.data.accessToken,
            private_key
          );
          localStorage.setItem("accessToken", JSON.stringify(response.data));
          localStorage.setItem("user", JSON.stringify(verifyToken));
          localStorage.setItem("photo", JSON.stringify(response.data.photo));
        }

        return response;
      });
  }
  refreshToken(username, password) {
    return axios
      .post(
        API_URL + "signin",
        { username, password },
        { headers: refreshToken() }
      )
      .then((response) => {
        if (response.data.accessToken) {
          const verifyToken = jwt.verify(
            response.data.accessToken,
            private_key
          );
          localStorage.setItem("accessToken", JSON.stringify(response.data));
          localStorage.setItem("user", JSON.stringify(verifyToken));
        }

        return response;
      });
  }
  logout() {
    cookies.remove(cookieNameGenerator("ssocookies"));
    cookies.remove(cookieNameGenerator("ssoaccount"));
    cookies.remove(cookieNameGenerator("ssophonenumber"));

    localStorage.clear();
  }

  register(username, email, password) {
    return axios.post(
      API_URL + "signup",
      {
        username,
        email,
        password,
      },
      { headers: authHeader() }
    );
  }

  loginSSO(payload) {
    return axios
      .post(`${API_URL_DEFAULT}auth/signin-sso`, payload, {
        headers: authHeader(),
      })
      .then(async (response) => {
        if (response.data.accessToken) {
          const verifyToken = jwt.verify(
            response.data.accessToken,
            private_key
          );
          localStorage.setItem("accessToken", JSON.stringify(response.data));
          localStorage.setItem("user", JSON.stringify(verifyToken));
          localStorage.setItem("photo", JSON.stringify(response.data.photo));
        }

        return response;
      });
  }
  getMenu(role) {
    return axios
      .get(API_URL_DEFAULT + "authorization/", {
        headers: authHeader(),
        params: role,
      })
      .then((response) => {
        localStorage.setItem("menu", JSON.stringify(response.data));
        return response.data;
      });
  }
}

export default new AuthService();
