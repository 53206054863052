import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class UplaodSingleService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + "upload_single", payload, {
      headers: authHeader(),
    });
  }

  read(query) {
    return axios.get(API_URL_DEFAULT + "upload_single/?" + query, {
      headers: authHeader(),
    });
  }

  edit(payload) {
    return axios.put(API_URL_DEFAULT + "upload_single", payload, {
      headers: authHeader(),
    });
  }

  delete(payload) {
    return axios.delete(API_URL_DEFAULT + "upload_single", {
      data: payload,
      headers: authHeader(),
    });
  }

  readExcel(query) {
    return axios.get(API_URL_DEFAULT + "upload_single/readExcel?" + query, {
      headers: authHeader(),
    });
  }
}

export default new UplaodSingleService();
