import { set } from "date-fns";
import debug from "debug";
import React, { useState, useRef } from "react";
import { Table, Button, Col } from "react-bootstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";

const MultiTags = ({
  tag_names,
  tag_details,
  select_items_tags,
  select_items_tags_details,
  onChange,
  onAdd,
  onRemove,
  readOnly,
  isEdit,
  errors,
  defaultOptions,
  loadOptions,
  onChangeTags,
}) => {
  const inputRef = useRef(null);
  const [isNoOption, setIsNoOption] = useState(false);
  const [input, setInput] = useState("");

  //to send inserted data back to parent when there are some changes
  const handleOnChange = (name, idx, newValue) => {
    let new_tag_names = [...tag_names];
    let new_tag_details = [...tag_details];
    let new_select_tag_details = [...select_items_tags_details];
    if (name == "tag_names") {
      if (new_tag_names[idx] != "") {
        new_tag_details[idx] = [];
      }
      new_tag_names[idx] = newValue.label;
    }
    if (name == "tag_details") {
      let val = newValue.map((v) => v.label);
      new_tag_details[idx] = val;
    }
    onChange(new_tag_names, new_tag_details, new_select_tag_details, isEdit);
  };

  //when remove button clicked
  const handleOnRemove = (idx) => {
    if (tag_details.length > 1) {
      let new_tag_names = [...tag_names];
      let new_tag_details = [...tag_details];
      let new_select_tag_details = [...select_items_tags_details];
      new_tag_names.splice(idx, 1);
      new_tag_details.splice(idx, 1);
      new_select_tag_details.splice(idx, 1);
      onChange(new_tag_names, new_tag_details, new_select_tag_details, isEdit);
    }
  };

  //when add button clicked
  const handleOnAdd = () => {
    let new_tag_names = [...tag_names];
    let new_tag_details = [...tag_details];
    let new_select_tag_details = [...select_items_tags_details];
    new_tag_names.unshift("");
    new_tag_details.unshift("");
    new_select_tag_details.unshift("");
    onChange(new_tag_names, new_tag_details, new_select_tag_details, isEdit);
  };

  //to handle custom tag details is added to the list
  const handleAddCustomTagDetail = (value, idx) => {
    let new_value = {
      value: 0,
      label: value,
    };
    let temp_tag_details = tag_details[idx];
    if (Array.isArray(temp_tag_details)) {
      temp_tag_details = temp_tag_details.map((tag, idx) => {
        return { value: idx, label: tag };
      });
      new_value.value = temp_tag_details.length;
      temp_tag_details.push(new_value);
    } else {
      temp_tag_details = [new_value];
    }

    let state = inputRef.current.state;
    new_value.value = state.defaultOptions.length;
    state.defaultOptions.push(new_value);
    state.loadedOptions.push(new_value);
    onChangeTags(temp_tag_details, idx);
    setIsNoOption(false);
  };

  const handleButtonNoOptions = (value, idx) => {
    if (tag_details[idx] && tag_details[idx].some((det) => det == value)) {
      setIsNoOption(false);
      return (
        <div className="d-flex flex-row align-items-center justify-content-between">
          <p>Already Picked</p>
        </div>
      );
    } else {
      return (
        <div className="d-flex flex-row align-items-center w-100 justify-content-between">
          <div>No Options</div>
          {value && (
            <Button
              variant="outline-success"
              size="sm"
              onClick={() => {
                handleAddCustomTagDetail(value, idx);
              }}
            >
              <i className="fas fa-plus"></i>
            </Button>
          )}
        </div>
      );
    }
  };
  return (
    <>
      <div className="d-flex flex-row align-items-center">
        <label htmlFor="tags">
          Tags <span style={{ color: "red" }}>*</span>
        </label>
        {!readOnly && (
          <Button
            className="ml-2 mb-2"
            variant="outline-success"
            size="sm"
            onClick={() => handleOnAdd()}
          >
            <i className="fas fa-plus"></i>
          </Button>
        )}
      </div>
      <Table size="sm" hover bordered>
        <thead className="bg-info text-center">
          <tr key={`h`}>
            <th key={`h0`}>No.</th>
            <th key={`h1`}>Tag Name</th>
            <th key={`h2`}>Tag Details</th>
            {!readOnly && <th key={`h3`}>Action</th>}
          </tr>
        </thead>
        <tbody>
          {tag_names.map((nm, idx) => [
            <tr key={`d${idx}`}>
              <td key={`d0${idx}`} className="text-center col-sm-1">
                {" "}
                {idx + 1}
              </td>
              <td key={`d1${idx}`} className="col-sm-4">
                <Select
                  onChange={handleOnChange.bind(this, "tag_names", idx)}
                  options={select_items_tags}
                  value={select_items_tags.filter((e) => e.label === nm)}
                  isDisabled={readOnly}
                  placeholder="Tag Names"
                />
              </td>
              <td key={`d2${idx}`} className="col-sm-4">
                <div>
                  <AsyncSelect
                    styles={{
                      multiValueRemove: (base) => ({
                        ...base,
                        display: readOnly ? "none" : "",
                      }),
                      multiValue: (styles) => {
                        return {
                          ...styles,
                          backgroundColor: "#2AA9BE",
                          color: "white",
                        };
                      },
                      multiValueLabel: (styles) => ({
                        ...styles,
                        color: "white",
                        fontWeight: "bold",
                      }),
                    }}
                    key={JSON.stringify(tag_names[idx])}
                    isMulti
                    onChange={handleOnChange.bind(this, "tag_details", idx)}
                    loadOptions={(input) =>
                      loadOptions(input, idx, tag_names[idx])
                    }
                    defaultValue={
                      defaultOptions !== true
                        ? defaultOptions.filter((e) => {
                            return tag_details.some((dt) => dt == e.label);
                          })
                        : tag_details[idx]
                        ? tag_details[idx].map((dt, idx) => {
                            return {
                              value: idx,
                              label: dt,
                            };
                          })
                        : null
                    }
                    isDisabled={readOnly || tag_names[idx].length == 0}
                    defaultOptions={defaultOptions}
                    cacheOptions
                    placeholder="Tag Details"
                    closeMenuOnSelect={false}
                    ref={inputRef}
                    isClearable={!readOnly}
                    noOptionsMessage={(val) => {
                      setIsNoOption(true);
                      return handleButtonNoOptions(val.inputValue, idx);
                    }}
                    onInputChange={setInput}
                    onKeyDown={(e) => {
                      if (isNoOption && e.key == "Enter" && input) {
                        e.preventDefault();
                        handleAddCustomTagDetail(input, idx);
                      }
                    }}
                  />
                </div>
              </td>
              {!readOnly && (
                <td key={`d3${idx}`} className="col-sm-1 text-center">
                  <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={() => handleOnRemove(idx)}
                  >
                    <i className="fas fa-times"></i>
                  </Button>
                </td>
              )}
            </tr>,
          ])}
        </tbody>
      </Table>
      {errors.tags && (
        <div
          style={{
            width: "100%",
            marginTop: ".25rem",
            fontSize: "80%",
            color: " #dc3545",
          }}
        >
          {errors.tags}{" "}
        </div>
      )}
    </>
  );
};

export default MultiTags;
