import React from "react";
import { Button, Alert } from "react-bootstrap";

const TagDetails = ({
  details,
  readOnly,
  errors,
  onChange,
  onRemove,
  onAdd,
  isEdit,
}) => {
  const handleAddDetail = (type) => {
    onAdd(type);
  };
  const handleRemoveDetail = (type, idx) => {
    onRemove(type, idx);
  };

  const onChangeDetail = (type, idx, value) => {
    onChange(type, idx, value);
  };

  return (
    <>
      <div className="form-group">
        <label htmlFor="tags">Tag Details </label>
        {!readOnly && (
          <Button
            className="ml-2"
            variant="outline-success"
            size="sm"
            name="details"
            onClick={() => handleAddDetail(isEdit ? "details_edit" : "details")}
          >
            <i className="fas fa-plus" />
          </Button>
        )}
      </div>

      <div
        className="d-flex flex-column flex-wrap my-2 w-100"
        style={{
          maxHeight: "300px",
          overflow: "auto",
        }}
      >
        {details.length > 0 ? (
          details.map((item, idx) => [
            <div className="input-group align-items-center mb-3 mr-2 w-50">
              <input
                type="text"
                className="form-control form-control"
                placeholder="Detail Desc"
                name="details"
                value={details[idx] || ""}
                onChange={(any) =>
                  onChangeDetail(
                    isEdit ? "details_edit" : "details",
                    idx,
                    any.target.value
                  )
                }
                style={{ resize: "none" }}
                readOnly={readOnly}
              />
              {!readOnly && (
                <div className="input-group-append">
                  <button
                    className="btn btn-outline-danger"
                    type="button"
                    name="details"
                    onClick={() =>
                      handleRemoveDetail(
                        isEdit ? "details_edit" : "details",
                        idx
                      )
                    }
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              )}
            </div>,
          ])
        ) : (
          <Alert variant={"warning"}>Tag Details not found !</Alert>
        )}
      </div>
    </>
  );
};

export default TagDetails;
