import React, { Component } from "react";
import Notification from "./Notification";
import { logout, goONELNKHomapage } from "../../modules/AuthModule";

const SSO_HOMEPAGE = process.env.REACT_APP_SSO_HOMEPAGE || null;
const IS_SSO = process.env.REACT_APP_SSO == "true" || false;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        photo: "./images/user.png",
        name: "",
      },
      notification: [],
      currentUser: {},
      photo: {},
    };
  }

  logOut = () => {
    if (!IS_SSO) {
      logout();
      window.location.reload();
    }
    if (IS_SSO) {
      goONELNKHomapage();
      window.location.href = SSO_HOMEPAGE;
    }
  };

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    const photo = JSON.parse(localStorage.getItem("photo"));
    if (user) {
      this.setState({
        currentUser: user,
        photo: photo,
      });
    }

    if (localStorage.getItem("collapse") === "Y") {
      document.getElementById("doc_body")?.classList.add("sidebar-collapse");
    } else {
      document.getElementById("doc_body")?.classList.remove("sidebar-collapse");
    }
  }

  handleCollapse = () => {
    if (localStorage.getItem("collapse")) {
      if (localStorage.getItem("collapse") === "Y") {
        localStorage.setItem("collapse", "N");
      } else {
        localStorage.setItem("collapse", "Y");
      }
    } else {
      localStorage.setItem("collapse", "Y");
    }
  };

  render() {
    const { user, notification, currentUser, photo } = this.state;
    return (
      <div>
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          {/* Left navbar links */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                onClick={this.handleCollapse}
                className="nav-link"
                data-widget="pushmenu"
                href="#!"
                role="button"
              >
                <i className="fas fa-bars" />
              </a>
            </li>
          </ul>
          {process.env.REACT_APP_ENV &&
            process.env.REACT_APP_ENV !== "production" && (
              <div className="blink">
                {process.env.REACT_APP_ENV.toUpperCase()}
              </div>
            )}
          {/* Right navbar links */}
          <ul className="navbar-nav ml-auto">
            {/* Notifications Dropdown Menu */}
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#!">
                <i className="far fa-bell" />
                <span className="badge badge-warning navbar-badge">
                  {notification.length}
                </span>
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span className="dropdown-item dropdown-header">
                  {notification.length} Notifications
                </span>

                <div className="dropdown-divider" />
                {notification && notification.length > 0
                  ? notification.map((item, i) => {
                      return (
                        <Notification
                          message={item.message}
                          timestamp={item.timestamp}
                          key={i}
                        />
                      );
                    })
                  : ""}
                <a href="#!" className="dropdown-item dropdown-footer">
                  See All Notifications
                </a>
              </div>
            </li>
            <li className="nav-item dropdown user-menu">
              <a
                href="#!"
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
              >
                <img
                  src={
                    photo &&
                    Object.keys(photo).length &&
                    photo.error === undefined
                      ? `data:${photo?.mimetype};base64,${photo?.file}`
                      : user.photo
                  }
                  className="user-image img-circle elevation-2"
                  alt=""
                />
                <span className="d-none d-md-inline">
                  {currentUser && currentUser.details
                    ? currentUser.details.hris_org_tree.current_person
                        .person_name
                    : user.name}
                </span>
              </a>
              <ul
                className="dropdown-menu dropdown-menu-lg dropdown-menu-right"
                style={{ marginLeft: "-157px" }}
              >
                {/* User image */}
                <li className="user-header bg-primary">
                  <img
                    src={
                      photo &&
                      Object.keys(photo).length &&
                      photo.error === undefined
                        ? `data:${photo?.mimetype};base64,${photo?.file}`
                        : user.photo
                    }
                    className="img-circle elevation-2"
                    alt=""
                  />
                  <p>
                    {currentUser && currentUser.details
                      ? currentUser.details.hris_org_tree.current_person
                          .person_name
                      : user.name}
                  </p>
                </li>
                {/* Menu Footer*/}
                <li className="user-footer">
                  <a href="#!" className="btn btn-default btn-flat">
                    Profile
                  </a>
                  <button
                    type="button"
                    className="btn btn-default btn-flat float-right"
                    onClick={this.logOut}
                  >
                    <i class="fas fa-sign-out-alt"></i>{" "}
                    {IS_SSO ? "OneLNK HomePage" : "Logout"}
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default Header;
