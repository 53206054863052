import React, { Component } from "react";
import { read } from "../../modules/DashboardModule";

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: {},
      list_dashboard: [],
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    let query = `nik=${user.username}`;
    read(query).then((response) => {
      this.setState({
        list_dashboard: response,
      });
    });

    if (user) {
      this.setState({
        currentUser: user,
      });
    }
  }
  render() {
    const { currentUser, list_dashboard } = this.state;
    return (
      <div>
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6"></div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </div>
          {/* /.content-header */}
          {/* Main content */}
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="info-box bg-gradient-info">
                    <div className="info-box-content">
                      <center>
                        <h2>
                          Welcome{" "}
                          {currentUser && currentUser.details
                            ? currentUser.details.hris_org_tree.current_person
                                .person_name
                            : ""}
                        </h2>
                        <p>Simplicity your work by using our apps</p>
                      </center>
                    </div>
                    {/* /.info-box-content */}
                  </div>
                  {/* /.info-box */}
                </div>
                {/* /.col */}
              </div>
              {list_dashboard.length > 0
                ? list_dashboard.map((x) => {
                    return [
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                          <div className="card card-info">
                            <div className="card-header ">
                              <h1 className="card-title">{x.title}</h1>
                              <div className="card-tools float-right"></div>
                            </div>
                            <div className="card-body">
                              <div>
                                <iframe
                                  src={x.url}
                                  height="800px"
                                  width="100%"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>,
                    ];
                  })
                : ""}
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
          {/* /.content */}
        </div>
      </div>
    );
  }
}
export default Content;
