import React, { Component } from "react";
import RemoteTable from "../components/RemoteTable";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import XLSX from "xlsx";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import RemoteAll from "../components/RemoteAll";

import {
  addTags,
  editTags,
  readTags,
  deleteTags,
  readSelectTags,
} from "../modules/TagsModule";
import { readClustersSearch } from "../modules/ClustersModule";
import {
  createUploadSingle,
  editUploadSingle,
  readUploadSingle,
  deleteUploadSingle,
  readExcel,
} from "../modules/UploadSingleModule";

import debug from "debug";
import id from "date-fns/esm/locale/id/index.js";
import TagDetails from "../components/TagDetails";
import MultiTags from "../components/MultiTags";

class UploadSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      submitted_create: false,
      showModal: false,
      showModalDailySpotPrice: false,
      loading: false,
      disableButtonNotification: false,
      seconds: 60,
      readOnly: false,
      currencyreadOnly: false,
      unitreadOnly: false,
      isClearable: true,
      collection: {},
      list_datatable: [],
      errors: {},
      errors_edit: {},
      id: null,
      requester: null,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      search: "",
      production_grade: null,
      read_type: "",
      formula: null,
      select_items_material: [],
      select_items_material_raw: [],
      raw_data_excel: [],
      material_code: null,
      data_formula: [
        {
          material_raw_code: "",
          material_raw_description: "",
          qty: "",
        },
      ],
      data_formula_update: [
        {
          material_raw_code: "",
          material_raw_description: "",
          qty: "",
        },
      ],
      columns_formula: [
        {
          text: "Material",
          type: "select",
        },
        {
          text: "Qty",
          type: "numeric",
          val: "qty",
        },
        {
          text: "Action",
          type: "del",
        },
      ],
      columns: [
        {
          dataField: "nama",
          text: "Name",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "whatsapp",
          text: "Whatsapp",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "email",
          text: "Email",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "alamat",
          text: "Alamat",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "date",
          text: "Date",
          classes: "text-center",
          formatter: this.GetDateFormat,
          sort: true,
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ],
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "raw_data.Date",
      sortOrder: "desc",
      defaultSorted: [
        {
          dataField: "raw_data.Date",
          order: "desc",
        },
      ],
      cellEditProps: {},
      filter_by: null,
      errors_search: {},
      isSearch: false,
      query_material: `sortBy=name&sortOrder=asc&page=1&sizePerPage=10000&constraint=material_type&constraint_value=ZSFG&search=powder`,
      tag_name_edit: null,
      validated: false,
      details: [],
      details_add: [""],
      submitted_edit: false,
      date: null,
      cluster: "",
      tag: null,
      tag_name: null,
      select_items_cluster: [],
      select_items_tag: [],
      select_items_tag_detail: [],
      select_items_tag_detail_edit: [],
      select_items_tag_detail_filter: [],

      isValidCluster: true,
      isValidTag: true,
      isValidTagDetail: true,
      isValidClusterEdit: true,
      isValidTagEdit: true,
      isValidTagDetailEdit: true,
      errors_add_tag: {},
      submitted_add_tags: false,
      showModalAddTags: false,
      tag_name_add: null,
      name: null,
      name_edit: null,
      email: null,
      email_edit: null,
      wa: null,
      wa_edit: null,
      alamat: null,
      alamat_edit: null,
      kota: null,
      kota_edit: null,
      provinsi: null,
      provinsi_edit: null,
      jenis_kelamin: null,
      jenis_kelamin_edit: null,
      tanggal_lahir: null,
      tanggal_lahir_edit: null,
      date_start: null,
      date_finish: null,
      tag_name_filter: null,
      pekerjaan: null,
      pekerjaan_edit: null,
      isLoading: true,
      showModalEdit: false,
      date_edit: null,
      select_items_tag_detail_edit: [],
      tags: { tag_names: [""], tag_details: [""] },
      tag_names: [""],
      tag_details: [""],
      select_items_tags_details: [""],
      tag_names_edit: [""],
      tag_details_edit: [""],
      tag_details_filter_arr: [""],
      test: [],
      loadFromState: false,
      filter_category: [
        "Date",
        "Nama",
        "Kota",
        "Pekerjaan",
        "Email",
        "Provinsi",
        "Whatsapp",
        "Alamat",
        "Tags",
        "Jenis_Kelamin",
        "Tanggal_Lahir",
      ],
      filter_selected: [{ filter_by: "" }],
      refFilter: [],
      formatted_query: null,
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    const { sortBy, sortOrder, page, sizePerPage } = this.state;
    const query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;

    readClustersSearch().then((response) => {
      this.setState({
        select_items_cluster: response,
      });
    });

    readSelectTags().then((response) => {
      this.setState({
        select_items_tag: response,
      });
    });

    readUploadSingle(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
        isLoading: false,
      });
    });

    readExcel(query_string).then((response) => {
      this.setState({
        raw_data_excel: response,
      });
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }

    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        requester: user.username,
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data, () => {});
  };

  onChangeFilter = (idx, type, any) => {
    const { filter_selected } = this.state;
    if (type == "filter_by") {
      let filter = any.target.value;
      if (!filter_selected.some((fil) => fil.filter_by == filter)) {
        let copy_filter_selected = [...filter_selected];
        copy_filter_selected[idx] = { filter_by: filter };
        if (filter == "Tags") {
          copy_filter_selected[idx].tag_details_filter = [];
          copy_filter_selected[idx].tag_name_filter = [];
        }
        this.setState({
          filter_selected: [...copy_filter_selected],
        });
      } else {
        Swal.fire({
          title: "Cancelled",
          text: `${filter} is already picked!`,
          icon: "error",
          showCancelButton: true,
          confirmButtonText: "Okay",
        });
      }
    } else if (type == "tag_details_filter") {
      const { filter_selected } = this.state;

      let val = any.map((v) => v.label);
      let copy_filter_selected = [...filter_selected];
      copy_filter_selected[idx][type] = val;

      this.setState({
        filter_selected: copy_filter_selected,
      });
    } else if (type == "tag_name_filter") {
      const { filter_selected } = this.state;

      if (this.refTagDetails) {
        this.refTagDetails.state.value = [];
      }
      let val = any.map((v) => v.label);
      let copy_filter_selected = [...filter_selected];
      copy_filter_selected[idx][type] = val;

      readTags(`multi_tag_search=${val.toString()}`).then((response) => {
        let details_filter =
          response.foundData.length > 0 &&
          response.foundData.map((dtl) => {
            let a = dtl.details.map((det, idx) => {
              return { value: idx, label: det };
            });
            return a;
          });

        this.setState({
          select_items_tag_detail_filter: details_filter
            ? [].concat(...details_filter)
            : [],
          filter_selected: copy_filter_selected,
        });
      });
    } else {
      let copy_filter_selected = [...filter_selected];
      copy_filter_selected[idx][type] = any;
      this.setState({
        filter_selected: [...copy_filter_selected],
      });
    }
  };

  onChangeDetail = (any, idx, value) => {
    const details = this.state[any];
    let temp = [...details];

    temp[idx] = value;
    this.setState({
      [any]: [...temp],
    });
  };

  handleDate = (name, date) => {
    this.setState({
      [name]: date,
    });
  };

  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]:
          name == "details" || name == "details_edit"
            ? newValue
            : newValue.label,
      });

      if (name == "tag_name") {
        if (this.refTagDetails) {
          this.refTagDetails.state.value = [];
        }
        this.setState({
          isValidTag: true,
          details: [],
        });
        readTags(`explicit_search=${newValue.label}`).then((response) => {
          let details =
            response.foundData.length > 0 &&
            response.foundData[0].details.map((det, idx) => {
              return { value: idx, label: det };
            });
          this.setState({
            select_items_tag_detail: details ? details : [],
          });
        });
      }
      if (name == "tag_details_filter") {
        let val = newValue.map((v) => v.label);

        this.setState({
          tag_details_filter: val.toString(),
          tag_details_filter_arr: val,
        });
      }
      if (name == "tag_name_filter") {
        if (this.refTagDetails) {
          this.refTagDetails.state.value = [];
        }
        let val = newValue.map((v) => v.label);

        readTags(`multi_tag_search=${val.toString()}`).then((response) => {
          let details_filter =
            response.foundData.length > 0 &&
            response.foundData.map((dtl) => {
              let a = dtl.details.map((det, idx) => {
                return { value: idx, label: det };
              });
              return a;
            });

          this.setState({
            select_items_tag_detail_filter: details_filter
              ? [].concat(...details_filter)
              : [],
            tag_name_filter: val.toString(),
          });
        });
      }
      if (name == "details") {
        this.setState({
          isValidTagDetail: true,
        });
      }
      if (name == "cluster") {
        this.setState({
          isValidCluster: true,
        });
      }
      if (name == "tag_name_edit") {
        if (this.refTagDetailsEdit) {
          this.refTagDetailsEdit.state.value = [];
        }
        this.setState({
          isValidTagEdit: true,
          details_edit: [],
        });
        readTags(`search=${newValue.label}`).then((response) => {
          let details =
            response.foundData.length > 0 &&
            response.foundData[0].details.map((det, idx) => {
              return { value: idx, label: det };
            });
          this.setState({
            select_items_tag_detail_edit: details ? details : [],
          });
        });
      }
      if (name == "details_edit") {
        this.setState({
          isValidTagDetailEdit: true,
        });
      }
      if (name == "cluster_edit") {
        this.setState({
          isValidClusterEdit: true,
        });
      }
    } else {
      if (name !== "details") {
        this.setState({
          [name]: null,
          [name + "_code"]: null,
        });
      } else {
        this.setState({
          details: [],
        });
      }
      if (name == "tag_name") {
        this.setState({
          isValidTag: false,
          details: [],
        });
      }
      if (name == "details") {
        this.setState({
          isValidTagDetail: false,
        });
      }
      if (name == "cluster") {
        this.setState({
          isValidCluster: false,
        });
      }
      if (name == "tag_name_edit") {
        this.setState({
          isValidTagEdit: false,
          details: [],
        });
      }
      if (name == "details_edit") {
        this.setState({
          isValidTagDetailEdit: false,
        });
      }
      if (name == "cluster_edit") {
        this.setState({
          isValidClusterEdit: false,
        });
      }
    }
  };

  //date format on datatable
  GetDateFormat = (cell, row) => {
    const date_formatted = dateFormat(
      new Date(row.raw_data.Date),
      "dd mmmm yyyy"
    );
    return <div>{date_formatted}</div>;
  };
  //action button
  GetActionFormat = (cell, row) => {
    const { isEdit, isDelete } = this.state;
    return (
      <div>
        {isEdit && (
          <button
            key={row._id}
            type="button"
            className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            onClick={() => this.toDetail(row)}
          >
            <i className="fas fa-edit" key={row._id}></i>
          </button>
        )}

        <button
          key={row._id}
          type="button"
          className="btn btn-success btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => this.toView(row)}
        >
          <i className="fas fa-eye" key={row._id}></i>
        </button>

        {isDelete && (
          <button
            type="button"
            className="btn btn-danger btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            data-id={row._id}
            onClick={() => this.deleteData(row._id)}
          >
            <i className="fas fa-trash"></i>
          </button>
        )}
      </div>
    );
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    return <div>{isNaN(cell) ? "" : thousandformat(cell, ".", ".", ",")}</div>;
  };

  deleteData = (id) => {
    const { history } = this.props;
    const { requester } = this.state;
    let collection = { _id: id, requester: requester };
    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, cancel entries",
      }).then((result) => {
        if (result.value) {
          deleteUploadSingle(collection)
            .then((response) => {
              toast.success("Data has been cancelled successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-salesforecast-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been cancelled successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.setState({
                  isLoading: true,
                });
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-salesforecast-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Information", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  toDetail = (row) => {
    let tags = this.restructureTagDetails(row.raw_data.Tags);
    this.setState(
      {
        id: row._id,
        date_edit: new Date(row.raw_data.Date),
        tag_name_edit: row.raw_data.Tags,
        cluster_edit: row.raw_data.Cluster,
        name_edit: row.raw_data.Nama,
        wa_edit: row.raw_data.Whatsapp,
        email_edit: row.raw_data.Email,
        alamat_edit: row.raw_data.Alamat,
        kota_edit: row.raw_data.Kota,
        provinsi_edit: row.raw_data.Provinsi,
        jenis_kelamin_edit: row.raw_data.Jenis_Kelamin,
        tanggal_lahir_edit:
          row.raw_data.Tanggal_Lahir && new Date(row.raw_data.Tanggal_Lahir),
        pekerjaan_edit: row.raw_data.Pekerjaan,
        tag_names_edit: [...tags.tag_names],

        tag_details_edit: [...tags.tag_details],
      },
      () => {
        this.toggle();
      }
    );
  };

  toView = (row) => {
    let tags = this.restructureTagDetails(row.raw_data.Tags);
    this.setState(
      {
        id: row._id,
        date_edit: new Date(row.raw_data.Date),
        tag_name_edit: row.raw_data.Tags,
        cluster_edit: row.raw_data.Cluster,
        name_edit: row.raw_data.Nama,
        wa_edit: row.raw_data.Whatsapp,
        email_edit: row.raw_data.Email,
        alamat_edit: row.raw_data.Alamat,
        kota_edit: row.raw_data.Kota,
        provinsi_edit: row.raw_data.Provinsi,
        jenis_kelamin_edit: row.raw_data.Jenis_Kelamin,
        tanggal_lahir_edit:
          row.raw_data.Tanggal_Lahir && new Date(row.raw_data.Tanggal_Lahir),
        pekerjaan_edit: row.raw_data.Pekerjaan,
        tag_names_edit: [...tags.tag_names],

        tag_details_edit: [...tags.tag_details],
        readOnly: true,
      },
      () => {
        this.toggle();
      }
    );
  };

  restructureTagDetails = (tags) => {
    //split tag name by : ; ,
    let splitted_tags = tags.split(";");
    let arr_tag_names = [];
    let arr_tag_details = [];
    splitted_tags.forEach((tag) => {
      if (tag != "") {
        let splitted = tag.split(":");

        //get header
        arr_tag_names.push(splitted[0].trim());

        //get details
        let splitted_details = splitted[1].split(",");
        arr_tag_details.push(splitted_details);
      }
    });

    return { tag_names: [...arr_tag_names], tag_details: [...arr_tag_details] };
  };

  //toggle modal

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  toggleAddTags = () => {
    this.setState({
      showModalAddTags: !this.state.showModalAddTags,
    });
  };
  //on modal hide

  onModalHide = () => {
    readSelectTags().then((response) => {
      this.setState({
        select_items_tag: response,
      });
    });
    this.setState({
      id: null,
      submitted_edit: false,
      date_edit: null,
      cluster_edit: "",
      tag_name_edit: null,
      details_edit: null,
      nama_edit: null,
      email_edit: null,
      wa_edit: null,
      alamat_edit: null,
      kota_edit: null,
      provinsi_edit: null,
      jenis_kelamin: null,
      tanggal_lahir_edit: null,
      pekerjaan_edit: null,
      errors_edit: {},
      isValidTagEdit: true,
      isValidTagDetailEdit: true,

      showModal: !this.state.showModal,
      readOnly: false,
      select_items_tag_detail: [],
      select_items_tag_detail_edit: [],
    });
  };
  onModalHideEdit = () => {
    this.setState({
      id: null,
      date_edit: null,
      cluster_edit: "",
      tag_name_edit: null,
      details_edit: null,
      nama_edit: null,
      email_edit: null,
      wa_edit: null,
      alamat_edit: null,
      kota_edit: null,
      provinsi_edit: null,
      jenis_kelamin: null,
      tanggal_lahir_edit: null,
      pekerjaan_edit: null,

      showModalEdit: !this.state.showModalEdit,
      readOnly: false,
    });
  };
  onModalHideAddTags = () => {
    this.setState({
      tag_name_add: null,
      details_add: [""],
      submitted_add: false,

      showModalAddTags: !this.state.showModalAddTags,
      readOnly: false,
    });
  };
  //validate input
  validateForm = () => {
    let {
      tag_names,
      tag_details,
      errors,
      name,
      email,
      wa,
      alamat,
      date,
      cluster,
    } = this.state;

    if (!name) {
      errors.name = "This is field is required";
    } else {
      delete errors.name;
    }

    if (!email) {
      errors.email = "This is field is required";
    } else {
      delete errors.email;
    }

    if (!wa) {
      errors.wa = "This is field is required";
    } else {
      delete errors.wa;
    }

    if (!alamat) {
      errors.alamat = "This is field is required";
    } else {
      delete errors.alamat;
    }

    if (!date) {
      errors.date = "This is field is required";
    } else {
      delete errors.date;
    }

    if (tag_names.length > 0) {
      if (tag_names.some((item) => item == "")) {
        errors.tags = "Please check your input";
      } else {
        delete errors.tags;
      }
    }

    if (tag_details.length > 0) {
      if (tag_details.some((item) => item == "")) {
        errors.tags = "Please check your input";
      } else {
        delete errors.tags;
      }
    }

    return errors;
  };
  validateFormEdit = () => {
    let {
      tag_names_edit,
      tag_details_edit,
      errors_edit,
      name_edit,
      email_edit,
      wa_edit,
      alamat_edit,
      date_edit,
      cluster_edit,
    } = this.state;

    if (!name_edit) {
      errors_edit.name = "This is field is required";
    } else {
      delete errors_edit.name;
    }

    if (!email_edit) {
      errors_edit.email = "This is field is required";
    } else {
      delete errors_edit.email;
    }

    if (!wa_edit) {
      errors_edit.wa = "This is field is required";
    } else {
      delete errors_edit.wa;
    }

    if (!alamat_edit) {
      errors_edit.alamat = "This is field is required";
    } else {
      delete errors_edit.alamat;
    }

    if (!date_edit) {
      errors_edit.date = "This is field is required";
    } else {
      delete errors_edit.date;
    }

    if (tag_names_edit.length > 0) {
      if (tag_names_edit.some((item) => item == "")) {
        errors_edit.tags = "Please check your input";
      } else {
        delete errors_edit.tags;
      }
    }

    if (tag_details_edit.length > 0) {
      if (tag_details_edit.some((item) => item == "")) {
        errors_edit.tags = "Please check your input";
      } else {
        delete errors_edit.tags;
      }
    }

    return errors_edit;
  };
  //validate input
  validateFormAddTags = () => {
    let { tag_name_add, details_add, errors_add_tag } = this.state;

    if (!tag_name_add) {
      errors_add_tag.tag_name_add = "This is field is required";
    } else {
      delete errors_add_tag.tag_name_add;
    }

    if (details_add.length > 0) {
      details_add.forEach((item, idx) => {
        if (item == "") {
          errors_add_tag[`detail_${idx}`] = "This is field is required";
        } else {
          delete errors_add_tag[`detail_${idx}`];
        }
      });
    }
    return errors_add_tag;
  };
  validateFormCreate = (production_grade) => {
    let errors = this.state.errors_create;
    if (!production_grade) {
      errors.production_grade = "This is field required";
      this.setState({
        isValidProductionGrade: false,
      });
    }

    return errors;
  };

  validateSearch = (filter_by, search) => {
    let errors = this.state.errors_search;
    if (!filter_by) {
      errors.filter_by = "This is field required";
    }
    if (!search) {
      errors.search = "This is field required";
    }

    return errors;
  };
  //get data from backend
  reloadDataBackend = () => {
    const {
      sortBy,
      sortOrder,
      page,
      sizePerPage,
      filter_by,
      date_finish,
      date_start,
      tag_name_filter,
      tag_details_filter,
      search,
    } = this.state;
    const query_string_tags = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}&select=true`;

    let additioanl_query = "";
    if (["Date", "Tanggal_Lahir"].includes(filter_by)) {
      additioanl_query = `filter_by=${filter_by}&date_start=${dateFormat(
        date_start,
        "yyyy-mm-dd"
      )}&date_finish=${dateFormat(date_finish, "yyyy-mm-dd")}`;
    }

    if (["Tags"].includes(filter_by)) {
      additioanl_query = `filter_by=${filter_by}&tag_name_filter=${tag_name_filter}&tag_details_filter=${tag_details_filter}`;
    }

    if (
      [
        "Nama",
        "Kota",
        "Pekerjaan",
        "Email",
        "Provinsi",
        "Whatsapp",
        "Alamat",
        "Jenis_Kelamin",
      ].includes(filter_by)
    ) {
      additioanl_query = `filter_by=${filter_by}&keyword=${search}`;
    }

    const query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}&${additioanl_query}`;

    readClustersSearch().then((response) => {
      this.setState({
        select_items_cluster: response,
      });
    });

    readSelectTags().then((response) => {
      this.setState({
        select_items_tag: response,
      });
    });

    readUploadSingle(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
        isLoading: false,
      });
    });

    if (this.refTagDetails) {
      this.refTagDetails.state.value = [];
    }

    this.setState({
      date: null,
      cluster: "",
      tag_name: null,
      select_items_tag_detail: [],
      details: [],
      details_edit: [],
      details_add: [""],
      tag_name_add: null,
      name: null,
      name_edit: null,
      email: null,
      email_edit: null,
      wa: null,
      wa_edit: null,
      alamat: null,
      alamat_edit: null,
      kota: null,
      kota_edit: null,
      provinsi: null,
      provinsi_edit: null,
      jenis_kelamin: null,
      jenis_kelamin_edit: null,
      tanggal_lahir: null,
      tanggal_lahir_edit: null,
      pekerjaan: null,
      pekerjaan_edit: null,
      isValidTagEdit: true,
      isValidTagDetailEdit: true,
      errors_edit: {},
      errors: {},
      tag_names: [""],
      tag_details: [""],
    });
  };

  handleTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
    if (page == 0) {
      page = 1;
    }
    this.setState({
      sizePerPage: sizePerPage,
      page: page,
      sortOrder: sortOrder,
      isLoading: true,
    });

    const { sortBy, formatted_query } = this.state;

    const query_string = formatted_query
      ? `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}&${formatted_query}`
      : `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;

    readUploadSingle(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
        isLoading: false,
      });
    });
  };

  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const { history } = this.props;
    const {
      requester,
      tag_name,
      details,
      errors,
      cluster,
      date,
      name,
      email,
      wa,
      alamat,
      kota,
      provinsi,
      jenis_kelamin,
      tanggal_lahir,
      pekerjaan,
      tag_names,
      tag_details,
    } = this.state;

    const user = JSON.parse(localStorage.getItem("user"));
    let department = user.details.hris_org_tree.current_person.nama_department;

    if (!this.validateForm()) {
      return;
    }

    let collection = {
      tag_names: tag_names,
      tag_details: tag_details,
      date: date,
      name: name,
      email: email,
      wa: wa,
      alamat: alamat,
      kota: kota,
      provinsi: provinsi,
      jenis_kelamin: jenis_kelamin,
      tanggal_lahir: tanggal_lahir,
      pekerjaan: pekerjaan,
      department: department,
      requester: requester,
    };
    if (
      requester &&
      tag_names.length > 0 &&
      Object.keys(errors).length == 0 &&
      tag_details.length > 0 &&
      date &&
      name &&
      email &&
      wa &&
      alamat &&
      department
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          createUploadSingle(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                //read data exchange rate on backend
                this.setState({
                  submitted: false,
                  isLoading: true,
                });
                this.reloadDataBackend();
                history.push("/master-data/upload-single");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-failed",
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      Swal.fire({
        title: "Not Allowed!",
        text: "Please check your input !",
        icon: "info",
      });
    }
  };
  //handle submit edit
  handleSubmitAdd = () => {
    this.setState({ submitted_add_tags: true });

    //get department
    const user = JSON.parse(localStorage.getItem("user"));
    let department = user.details.hris_org_tree.current_person.nama_department;

    if (!this.validateFormAddTags()) {
      return;
    }

    const { tag_name_add, details_add, requester, errors_add_tag } = this.state;

    let collection = {
      tag_name: tag_name_add,
      details: details_add,
      department: department,
      requester: requester,
      write_type: "update",
    };
    if (tag_name_add && Object.keys(errors_add_tag).length == 0) {
      //check if tag exists
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          addTags(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                //read data exchange rate on backend
                this.setState({
                  submitted: false,
                });
                this.toggleAddTags();
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-failed",
              });
              if (err.includes("update")) {
                let msg = err.split(".");
                Swal.fire({
                  title: `${msg[0]}!`,
                  text: `${msg[1]}`,
                  icon: "info",
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                }).then((result) => {
                  if (result.value) {
                    collection.accept_write = true;
                    addTags(collection)
                      .then((response) => {
                        toast.success("Data has been saved successfully", {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          toastId: "customId-add-daily-spot-price-success",
                        });
                        Swal.fire({
                          title: "Information",
                          icon: "success",
                          text: "Data has been saved successfully",
                          showConfirmButton: false,
                          timer: 2000,
                        }).then((result) => {
                          //read data exchange rate on backend
                          this.toggleAddTags();
                          this.setState({
                            submitted: false,
                          });
                          this.reloadDataBackend();
                        });
                      })
                      .catch((err) => {
                        toast.error(err, {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          toastId: "customId-add-daily-spot-price-failed",
                        });
                      });
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire("Cancelled", "Your data is safe :)", "error");
                  }
                });
              }
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      Swal.fire({
        title: "Not Allowed!",
        text: "Please check your input !",
        icon: "info",
      });
    }
  };
  handleSubmitEdit = (e) => {
    this.setState({ submitted_edit: true });
    const { history } = this.props;
    const {
      id,
      requester,
      errors_edit,
      cluster_edit,
      date_edit,
      name_edit,
      email_edit,
      wa_edit,
      alamat_edit,
      kota_edit,
      provinsi_edit,
      jenis_kelamin_edit,
      tanggal_lahir_edit,
      pekerjaan_edit,
      tag_names_edit,
      tag_details_edit,
    } = this.state;

    const user = JSON.parse(localStorage.getItem("user"));
    let department = user.details.hris_org_tree.current_person.nama_department;

    if (!this.validateFormEdit()) {
      return;
    }

    let collection = {
      _id: id,
      tag_names: tag_names_edit,
      tag_details: tag_details_edit,
      date: date_edit,
      name: name_edit,
      email: email_edit,
      wa: wa_edit,
      alamat: alamat_edit,
      kota: kota_edit,
      provinsi: provinsi_edit,
      jenis_kelamin: jenis_kelamin_edit,
      tanggal_lahir: tanggal_lahir_edit,
      pekerjaan: pekerjaan_edit,
      department: department,
      requester: requester,
    };
    if (
      id &&
      requester &&
      tag_names_edit.length > 0 &&
      Object.keys(errors_edit).length == 0 &&
      tag_details_edit.length > 0 &&
      date_edit &&
      name_edit &&
      email_edit &&
      wa_edit &&
      alamat_edit &&
      department
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          editUploadSingle(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                //read data exchange rate on backend
                this.setState({
                  submitted_edit: false,
                  isLoading: true,
                });
                this.reloadDataBackend();
                this.toggle();
                history.push("/master-data/upload-single");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-failed",
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      Swal.fire({
        title: "Not Allowed!",
        text: "Please check your input !",
        icon: "info",
      });
    }
  };
  addFilter = () => {
    const { filter_selected, filter_category } = this.state;
    let copy_filter_selected = [...filter_selected];
    if (copy_filter_selected.length != filter_category.length) {
      copy_filter_selected.push({ filter_by: "" });
      this.setState({
        filter_selected: [...copy_filter_selected],
      });
    } else {
      Swal.fire({
        title: "Cancelled",
        icon: "error",
        text: `Cannot add more than ${filter_category.length} filter`,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }
  };
  deleteFilter = (idx) => {
    const { filter_selected } = this.state;
    let copy_filter_selected = [...filter_selected];
    copy_filter_selected.splice(idx, 1);
    this.setState({
      filter_selected: copy_filter_selected,
    });
  };
  //handle search
  handleSearch = () => {
    const { search, page, sizePerPage, sortBy, sortOrder, filter_selected } =
      this.state;

    let filter_query = "filter_by=";
    let additioanl_query = "";
    filter_selected.map((fil) => {
      if (fil.filter_by) {
        filter_query += `${fil.filter_by},`;
        if (["Date", "Tanggal_Lahir"].includes(fil.filter_by)) {
          additioanl_query += `&date_start_${fil.filter_by}=${dateFormat(
            fil.date_start,
            "yyyy-mm-dd"
          )}&date_finish_${fil.filter_by}=${dateFormat(
            fil.date_finish,
            "yyyy-mm-dd"
          )}&`;
        }

        if (["Tags"].includes(fil.filter_by)) {
          additioanl_query += `&tag_name_filter_${fil.filter_by}=${fil.tag_name_filter}&tag_details_filter_${fil.filter_by}=${fil.tag_details_filter}&`;
        }

        if (
          [
            "Nama",
            "Kota",
            "Pekerjaan",
            "Email",
            "Provinsi",
            "Whatsapp",
            "Alamat",
            "Jenis_Kelamin",
          ].includes(fil.filter_by)
        ) {
          additioanl_query += `&keyword_${fil.filter_by}=${fil.search}&`;
        }
      }
    });
    //concat with filter by, and remove char ',' and '&' on the last query
    let formatted_additional_query = filter_query
      .slice(0, -1)
      .concat(additioanl_query.slice(0, -1));

    let page_ = 1;
    const query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page_}&sizePerPage=${sizePerPage}&${formatted_additional_query}`;

    readUploadSingle(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
        formatted_query: formatted_additional_query,
      });
    });
    readExcel(query_string).then((response) => {
      this.setState({
        raw_data_excel: response,
      });
    });
  };

  handleAddDetail = (any) => {
    const details = this.state[any];
    let temp = [...details];
    temp.push("");
    this.setState({
      [any]: [...temp],
    });
  };

  handleRemoveDetail = (any, idx) => {
    const details = this.state[any];
    if (details.length != 1) {
      let temp = [...details];
      temp.splice(idx, 1);
      this.setState(
        {
          [any]: [...temp],
          errors_add_tag: {},
        },
        () => {
          this.validateFormAddTags();
        }
      );
    }
  };

  exportFile = () => {
    /* convert state to workbook */
    const {
      search,
      sizePerPage,
      sortBy,
      sortOrder,
      filter_by,
      date_start,
      date_finish,
      tag_name_filter,
      tag_details_filter,
      raw_data_excel,
    } = this.state;

    const data = [
      [
        "Date",
        "Cluster",
        "Nama",
        "Email",
        "Whatsapp",
        "Alamat",
        "Kota",
        "Provinsi",
        "Jenis_Kelamin",
        "Tanggal_Lahir",
        "Pekerjaan",
        "Tags",
      ],
    ];

    //merge cells
    let merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 0 } },
      { s: { r: 0, c: 1 }, e: { r: 0, c: 1 } },
      { s: { r: 0, c: 2 }, e: { r: 0, c: 2 } },
      { s: { r: 0, c: 3 }, e: { r: 0, c: 3 } },
      { s: { r: 0, c: 4 }, e: { r: 0, c: 4 } },
      { s: { r: 0, c: 5 }, e: { r: 0, c: 5 } },
      { s: { r: 0, c: 6 }, e: { r: 0, c: 6 } },
      { s: { r: 0, c: 7 }, e: { r: 0, c: 7 } },
      { s: { r: 0, c: 8 }, e: { r: 0, c: 8 } },
      { s: { r: 0, c: 9 }, e: { r: 0, c: 9 } },
      { s: { r: 0, c: 10 }, e: { r: 0, c: 10 } },
      { s: { r: 0, c: 11 }, e: { r: 0, c: 11 } },
    ];

    raw_data_excel.map((item, idx) => {
      let temp = [];
      temp.push(item.raw_data.Date);
      temp.push(item.raw_data.Cluster);
      temp.push(item.raw_data.Nama);
      temp.push(item.raw_data.Email);
      temp.push(item.raw_data.Whatsapp);
      temp.push(item.raw_data.Kota);
      temp.push(item.raw_data.Alamat);
      temp.push(item.raw_data.Provinsi);
      temp.push(item.raw_data.Jenis_Kelamin);
      temp.push(item.raw_data.Tanggal_Lahir);
      temp.push(item.raw_data.Pekerjaan);
      temp.push(item.raw_data.Tags);
      data.push(temp);
    });

    const ws = XLSX.utils.aoa_to_sheet(data);
    ws["!merges"] = merge;
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Rawdata Utama");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "database_customer.xlsx");
  };

  onChangeMultiTags = (tag_names, tag_details, select_tag_details, isEdit) => {
    //check whether tag_names is already exists, then throw error
    let temp_tag_names = [...tag_names];

    let checked_tag_name = [];
    for (let tag_name of temp_tag_names) {
      if (tag_name !== "") {
        if (checked_tag_name.includes(tag_name)) {
          Swal.fire({
            title: "Please check your input!",
            icon: "error",
            text: "You cannot pick same Tag Name!",
          });
          return;
        } else {
          checked_tag_name.push(tag_name);
        }
      }
    }

    this.setState({
      [isEdit ? "tag_names_edit" : "tag_names"]: [...tag_names],
      [isEdit ? "tag_details_edit" : "tag_details"]: [...tag_details],
      select_items_tags_details: [...select_tag_details],
    });
  };
  getTags = (input, idx, tag_name) => {
    const { select_items_tags_details, loadFromState } = this.state;
    if (tag_name !== "") {
      const result = readTags(
        `explicit_search=${tag_name}&search_details=${input}&details=true&sizePerPage=100`
      ).then((response) => {
        let details =
          response.foundData.length > 0 &&
          response.foundData.map((det, idx) => {
            return { value: idx, label: det };
          });

        let temp_select = [...select_items_tags_details];

        temp_select[idx] = [...details];
        this.setState({ select_items_tags_details: temp_select });

        return temp_select[idx];
      });

      return result;
    }
  };

  onChangeTags = (tag_details, idx) => {
    const { select_items_tags_details } = this.state;

    let temp_select_tags = [...select_items_tags_details];
    temp_select_tags[idx] = tag_details;
    this.setState({
      select_items_tags_details: temp_select_tags,
      loadFromState: true,
    });
  };

  render() {
    let {
      disableButtonNotification,
      columns,
      defaultSorted,
      showModal,
      cellEditProps,
      list_datatable,
      id,
      qty,
      production_grade,
      formula,
      errors,
      errors_create,
      submitted_create,
      submitted,
      loading,
      isValidMaterial,
      isCreate,
      isRead,
      readOnly,
      isEdit,
      isDelete,
      search,
      page,
      sizePerPage,
      totalSize,
      filter_by,
      isSearch,
      select_items_material,
      material_code,
      read_type,
      data_formula,
      columns_formula,
      data_formula_update,
      select_items_material_raw,
      isClearable,
      tag_name,
      details,
      validated,
      submitted_edit,
      tag_name_edit,
      details_edit,
      errors_edit,
      date,
      cluster,
      tag,
      tag_details,
      select_items_cluster,
      isValidCluster,
      isValidTag,
      isValidTagDetail,
      isValidClusterEdit,
      isValidTagEdit,
      isValidTagDetailEdit,
      select_items_tag,
      showModalAddTags,
      submitted_add_tags,
      details_add,
      errors_add_tag,
      tag_name_add,
      select_items_tag_detail,
      name,
      name_edit,
      email,
      email_edit,
      wa,
      wa_edit,
      alamat,
      alamat_edit,
      kota,
      kota_edit,
      provinsi,
      provinsi_edit,
      jenis_kelamin,
      jenis_kelamin_edit,
      tanggal_lahir,
      tanggal_lahir_edit,
      pekerjaan,
      pekerjaan_edit,
      isLoading,
      showModalEdit,
      date_edit,
      tag_edit,
      cluster_edit,
      select_items_tag_detail_edit,
      date_start,
      date_finish,
      tag_name_filter,
      tags,
      tag_names,
      select_items_tags_details,
      tag_names_edit,
      tag_details_edit,
      select_items_tag_detail_filter,
      tag_details_filter_arr,
      test,
      loadFromState,
      filter_category,
      filter_selected,
      refFilter,
    } = this.state;

    const customStylesCluster = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? "#ddd" : isValidCluster ? "#ddd" : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidCluster
            ? "#ddd"
            : "green",
        },
      }),
    };
    const customStylesTag = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? "#ddd" : isValidTag ? "#ddd" : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused ? "#ddd" : isValidTag ? "#ddd" : "green",
        },
      }),
    };
    const customStylesTagDetail = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidTagDetail
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidTagDetail
            ? "#ddd"
            : "green",
        },
      }),
    };

    const customStylesClusterEdit = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidClusterEdit
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidClusterEdit
            ? "#ddd"
            : "green",
        },
      }),
    };
    const customStylesTagEdit = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? "#ddd" : isValidTagEdit ? "#ddd" : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidTagEdit
            ? "#ddd"
            : "green",
        },
      }),
    };
    const customStylesTagDetailEdit = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidTagDetailEdit
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidTagDetailEdit
            ? "#ddd"
            : "green",
        },
      }),
    };
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <Modal
            show={showModalAddTags}
            size="xl"
            backdrop="static"
            onHide={this.onModalHideAddTags}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>Add Tags</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <form
                autoComplete="off"
                className={
                  submitted_add_tags
                    ? "needs-validation was-validated"
                    : "needs-validation"
                }
                noValidate
                ref={(form) => (this.form_add_tags = form)}
              >
                <Row>
                  <Col xs={12} md={4}>
                    <div className="form-group">
                      <label htmlFor="tags">Tag Name</label>
                      <input
                        type="text"
                        className="form-control form-control"
                        name="tag_name_add"
                        placeholder="Tag Name"
                        required="required"
                        value={tag_name_add || ""}
                        onChange={this.onChangeValue}
                        min="0"
                      />
                      {errors_add_tag.tag_name_add && (
                        <div className="invalid-feedback">
                          {errors_add_tag.tag_name_add}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <TagDetails
                  details={details_add}
                  readOnly={false}
                  errors={errors_add_tag}
                  onRemove={(type, idx) =>
                    this.handleRemoveDetail("details_add", idx)
                  }
                  onChange={(type, idx, value) => {
                    this.onChangeDetail("details_add", idx, value);
                  }}
                  onAdd={(type) => {
                    this.handleAddDetail("details_add");
                  }}
                  isEdit={true}
                />
              </form>
            </ModalBody>
            <ModalFooter>
              {!readOnly && (
                <Button
                  variant="success"
                  onClick={this.handleSubmitAdd}
                  className="float-right"
                >
                  <i className="fas fa-save" /> Save
                </Button>
              )}
            </ModalFooter>
          </Modal>
          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader closeButton className="bg-info text-white">
              {" "}
              <ModalTitle>
                {readOnly ? "Detail" : "Update"} Upload Single Entity{" "}
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Container>
                <form
                  autoComplete="off"
                  className={
                    submitted_edit
                      ? "needs-validation was-validated"
                      : "needs-validation"
                  }
                  noValidate
                  ref={(form) => (this.form = form)}
                >
                  <Row>
                    <Col xs={12} md={3}>
                      <div className="form-group">
                        <label htmlFor="date">
                          Date <span style={{ color: "red" }}>*</span>
                        </label>
                        <DatePicker
                          selected={date_edit}
                          onChange={this.handleDate.bind(this, "date_edit")}
                          placeholderText="Date"
                          name="date_edit"
                          dateFormat="dd MMMM yyyy"
                          className="form-control form-control"
                          required
                          readOnly={readOnly}
                        />
                        {errors_edit.date && (
                          <div className="invalid-feedback">
                            {errors_edit.date}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className="form-group">
                        <label htmlFor="nama">
                          Nama <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control"
                          name="name_edit"
                          placeholder="Nama"
                          required="required"
                          value={name_edit || ""}
                          onChange={this.onChangeValue}
                          readOnly={readOnly}
                        />
                        {errors_edit.name && (
                          <div className="invalid-feedback">
                            {errors_edit.name}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className="form-group">
                        <label htmlFor="kota">Kota</label>
                        <input
                          type="text"
                          className="form-control form-control"
                          name="kota_edit"
                          placeholder="Kota"
                          value={kota_edit || ""}
                          onChange={this.onChangeValue}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className="form-group">
                        <label htmlFor="pekerjaan">Pekerjaan</label>
                        <input
                          type="text"
                          className="form-control form-control"
                          name="pekerjaan_edit"
                          placeholder="Pekerjaan"
                          value={pekerjaan_edit || ""}
                          onChange={this.onChangeValue}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={3}>
                      <div className="form-group">
                        <label htmlFor="email">
                          E-mail <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control form-control"
                          name="email_edit"
                          placeholder="E-mail"
                          required="required"
                          value={email_edit || ""}
                          onChange={this.onChangeValue}
                          readOnly={readOnly}
                        />
                        {errors_edit.email && (
                          <div className="invalid-feedback">
                            {errors_edit.email}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className="form-group">
                        <label htmlFor="tanggal_lahir">Tanggal Lahir</label>
                        <DatePicker
                          selected={tanggal_lahir_edit}
                          disabledKeyboardNavigation
                          onChange={this.handleDate.bind(
                            this,
                            "tanggal_lahir_edit"
                          )}
                          name="tanggal_lahir_edit"
                          placeholderText="Tanggal Lahir"
                          dateFormat="dd MMMM yyyy"
                          wrapperClassName="datePickerCustom"
                          className="form-control form-control"
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className="form-group">
                        <label htmlFor="kota">Provinsi</label>
                        <input
                          type="text"
                          className="form-control form-control"
                          name="provinsi_edit"
                          placeholder="Provinsi"
                          value={provinsi_edit || ""}
                          onChange={this.onChangeValue}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={3}>
                      <div className="form-group">
                        <label htmlFor="whatsapp">
                          Whatsapp <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control form-control Input-number-hide-step"
                          name="wa_edit"
                          placeholder="Whatsapp"
                          required="required"
                          value={wa_edit || ""}
                          onChange={this.onChangeValue}
                          readOnly={readOnly}
                        />
                        {errors_edit.wa && (
                          <div className="invalid-feedback">
                            {errors_edit.wa}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div
                        className={
                          errors_edit.jenis_kelamin != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="jenis_kelamin">Jenis Kelamin</label>

                        <div className="d-flex flex-row justify-content-around align-items-center">
                          <Form.Check
                            inline
                            label="Laki-Laki"
                            value="laki-laki"
                            name="jenis_kelamin_edit"
                            type="radio"
                            id={`inline-1`}
                            disabled={readOnly}
                            onChange={this.onChangeValue}
                            checked={jenis_kelamin_edit == "laki-laki"}
                          />
                          <Form.Check
                            inline
                            label="Perempuan"
                            value="perempuan"
                            name="jenis_kelamin_edit"
                            type="radio"
                            id={`inline-2`}
                            disabled={readOnly}
                            onChange={this.onChangeValue}
                            checked={jenis_kelamin_edit == "perempuan"}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className="form-group">
                        <label htmlFor="email">
                          Alamat <span style={{ color: "red" }}>*</span>
                        </label>
                        <textarea
                          className="form-control form-control"
                          name="alamat_edit"
                          placeholder="Alamat"
                          required="required"
                          value={alamat_edit || ""}
                          onChange={this.onChangeValue}
                          readOnly={readOnly}
                        />
                        {errors_edit.alamat && (
                          <div className="invalid-feedback">
                            {errors_edit.alamat}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row></Row>
                  <MultiTags
                    tag_names={tag_names_edit}
                    tag_details={tag_details_edit}
                    select_items_tags={select_items_tag}
                    select_items_tags_details={select_items_tags_details}
                    onChange={this.onChangeMultiTags}
                    defaultOptions={true}
                    loadOptions={this.getTags}
                    errors={errors_edit}
                    isEdit={true}
                    readOnly={readOnly}
                    onChangeTags={this.onChangeTags}
                  />
                  {!readOnly && (
                    <Row>
                      <Col xs={12} md={12}>
                        <Button
                          variant="success"
                          onClick={this.handleSubmitEdit}
                          className="float-right"
                        >
                          <i className="fas fa-save" /> Submit
                        </Button>
                      </Col>
                    </Row>
                  )}{" "}
                </form>
              </Container>
            </ModalBody>
          </Modal>
          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h1 className="card-title">Upload Single Entity</h1>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <form
                            autoComplete="off"
                            className={
                              submitted
                                ? "needs-validation was-validated"
                                : "needs-validation"
                            }
                            noValidate
                            ref={(form) => (this.form = form)}
                          >
                            <Row>
                              <Col xs={12} md={3}>
                                <div className="form-group">
                                  <label htmlFor="date">
                                    Date <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <DatePicker
                                    selected={date}
                                    onChange={this.handleDate.bind(
                                      this,
                                      "date"
                                    )}
                                    placeholderText="Date"
                                    name="date"
                                    dateFormat="dd MMMM yyyy"
                                    className="form-control form-control"
                                    required
                                  />
                                  {errors.date && (
                                    <div className="invalid-feedback">
                                      {errors.date}
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col xs={12} md={3}>
                                <div className="form-group">
                                  <label htmlFor="nama">
                                    Nama <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control form-control"
                                    name="name"
                                    placeholder="Nama"
                                    required="required"
                                    value={name || ""}
                                    onChange={this.onChangeValue}
                                    readOnly={readOnly}
                                  />
                                  {errors.name && (
                                    <div className="invalid-feedback">
                                      {errors.name}
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col xs={12} md={3}>
                                <div className="form-group">
                                  <label htmlFor="kota">Kota</label>
                                  <input
                                    type="text"
                                    className="form-control form-control"
                                    name="kota"
                                    placeholder="Kota"
                                    value={kota || ""}
                                    onChange={this.onChangeValue}
                                    readOnly={readOnly}
                                  />
                                </div>
                              </Col>
                              <Col xs={12} md={3}>
                                <div className="form-group">
                                  <label htmlFor="pekerjaan">Pekerjaan</label>
                                  <input
                                    type="text"
                                    className="form-control form-control"
                                    name="pekerjaan"
                                    placeholder="Pekerjaan"
                                    value={pekerjaan || ""}
                                    onChange={this.onChangeValue}
                                    readOnly={readOnly}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={3}>
                                <div className="form-group">
                                  <label htmlFor="email">
                                    E-mail{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control form-control"
                                    name="email"
                                    placeholder="E-mail"
                                    required="required"
                                    value={email || ""}
                                    onChange={this.onChangeValue}
                                    readOnly={readOnly}
                                  />
                                  {errors.email && (
                                    <div className="invalid-feedback">
                                      {errors.email}
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col xs={12} md={3}>
                                <div className="form-group">
                                  <label htmlFor="tanggal_lahir">
                                    Tanggal Lahir
                                  </label>
                                  <DatePicker
                                    selected={tanggal_lahir}
                                    disabledKeyboardNavigation
                                    onChange={this.handleDate.bind(
                                      this,
                                      "tanggal_lahir"
                                    )}
                                    name="tanggal_lahir"
                                    placeholderText="Tanggal Lahir"
                                    dateFormat="dd MMMM yyyy"
                                    wrapperClassName="datePickerCustom"
                                    className="form-control form-control"
                                    readOnly={readOnly}
                                  />
                                </div>
                              </Col>
                              <Col xs={12} md={3}>
                                <div className="form-group">
                                  <label htmlFor="kota">Provinsi</label>
                                  <input
                                    type="text"
                                    className="form-control form-control"
                                    name="provinsi"
                                    placeholder="Provinsi"
                                    value={provinsi || ""}
                                    onChange={this.onChangeValue}
                                    readOnly={readOnly}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={3}>
                                <div className="form-group">
                                  <label htmlFor="whatsapp">
                                    Whatsapp{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control form-control Input-number-hide-step"
                                    name="wa"
                                    placeholder="Whatsapp"
                                    required="required"
                                    value={wa || ""}
                                    onChange={this.onChangeValue}
                                    readOnly={readOnly}
                                  />
                                  {errors.wa && (
                                    <div className="invalid-feedback">
                                      {errors.wa}
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col xs={12} md={3}>
                                <div
                                  className={
                                    errors.jenis_kelamin !=
                                    "This field is required"
                                      ? "form-group"
                                      : "form-group has-error has-feedback"
                                  }
                                >
                                  <label htmlFor="jenis_kelamin">
                                    Jenis Kelamin
                                  </label>
                                  <div className="d-flex flex-row justify-content-around align-items-center">
                                    <Form.Check
                                      inline
                                      label="Laki-Laki"
                                      value="laki-laki"
                                      name="jenis_kelamin"
                                      type="radio"
                                      id={`inline-1`}
                                      onChange={this.onChangeValue}
                                      checked={jenis_kelamin == "laki-laki"}
                                    />
                                    <Form.Check
                                      inline
                                      label="Perempuan"
                                      value="perempuan"
                                      name="jenis_kelamin"
                                      type="radio"
                                      id={`inline-2`}
                                      onChange={this.onChangeValue}
                                      checked={jenis_kelamin == "perempuan"}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col xs={12} md={3}>
                                <div className="form-group">
                                  <label htmlFor="email">
                                    Alamat{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <textarea
                                    className="form-control form-control"
                                    name="alamat"
                                    placeholder="Alamat"
                                    required="required"
                                    value={alamat || ""}
                                    onChange={this.onChangeValue}
                                    readOnly={readOnly}
                                  />
                                  {errors.alamat && (
                                    <div className="invalid-feedback">
                                      {errors.alamat}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>

                            <Row className="mt-2">
                              <Col xs={12} md={6}>
                                {" "}
                                <MultiTags
                                  tag_names={tag_names}
                                  tag_details={tag_details}
                                  select_items_tags={select_items_tag}
                                  select_items_tags_details={
                                    select_items_tags_details
                                  }
                                  onChange={this.onChangeMultiTags}
                                  defaultOptions={true}
                                  loadOptions={this.getTags}
                                  errors={errors}
                                  isEdit={false}
                                  readOnly={readOnly}
                                  onChangeTags={this.onChangeTags}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={12}>
                                <Button
                                  variant="success"
                                  onClick={this.handleSubmit}
                                  className="float-right"
                                >
                                  <i className="fas fa-save" /> Submit
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              <div className="container-fluid">
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h1 className="card-title">Historical Data</h1>
                          <div className="card-tools float-right">
                            <Button
                              variant="success"
                              onClick={this.exportFile}
                              className="float-right btn btn-xs"
                            >
                              <i className="fas fa-file-export" /> Export
                            </Button>
                          </div>
                        </div>

                        <div className="card-body">
                          <div style={{ maxHeight: "30%" }}>
                            <Row>
                              {filter_selected.map((filter, idx) => {
                                return [
                                  <div className="d-flex flex-row w-100">
                                    {filter_selected.length > 1 && (
                                      <Button
                                        className=" align-self-center"
                                        size="sm"
                                        variant="outline-danger"
                                        onClick={() => this.deleteFilter(idx)}
                                      >
                                        <i
                                          className="fa fa-times"
                                          aria-hidden="true"
                                        ></i>
                                      </Button>
                                    )}
                                    <Col xs={12} md={1}>
                                      <label htmlFor="filter_by">
                                        Filter By{" "}
                                      </label>{" "}
                                      <div className="form-group">
                                        <select
                                          className="form-control form-control-sm"
                                          onChange={this.onChangeFilter.bind(
                                            this,
                                            idx,
                                            "filter_by"
                                          )}
                                          style={{
                                            padding: 0,
                                            fontWeight: "bold",
                                          }}
                                          value={filter.filter_by}
                                          ref={(ref) => (refFilter[idx] = ref)}
                                        >
                                          <option
                                            style={{ fontWeight: "normal" }}
                                            value=""
                                          >
                                            {filter_selected.length == 1
                                              ? "All"
                                              : "Choose"}
                                          </option>
                                          {filter_category.map((cat) => (
                                            <option value={cat}>{cat}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </Col>
                                    {["Date", "Tanggal_Lahir"].includes(
                                      filter.filter_by
                                    ) ? (
                                      <>
                                        <Col xs={12} md={1}>
                                          <div className="form-group">
                                            <label htmlFor="tanggal_lahir">
                                              Date Start
                                            </label>
                                            <DatePicker
                                              selected={filter.date_start}
                                              disabledKeyboardNavigation
                                              onChange={this.onChangeFilter.bind(
                                                this,
                                                idx,
                                                "date_start"
                                              )}
                                              name="date_start"
                                              placeholderText="Date Start"
                                              dateFormat="dd MMMM yyyy"
                                              wrapperClassName="datePickerCustom"
                                              className="form-control form-control-sm"
                                              readOnly={readOnly}
                                            />
                                          </div>
                                        </Col>
                                        <Col xs={12} md={1}>
                                          <div className="form-group">
                                            <label htmlFor="tanggal_lahir">
                                              Date Finish
                                            </label>
                                            <DatePicker
                                              selected={filter.date_finish}
                                              disabledKeyboardNavigation
                                              onChange={this.onChangeFilter.bind(
                                                this,
                                                idx,
                                                "date_finish"
                                              )}
                                              name="date_finish"
                                              placeholderText="Date Finish"
                                              dateFormat="dd MMMM yyyy"
                                              wrapperClassName="datePickerCustom"
                                              className="form-control form-control-sm"
                                              readOnly={readOnly}
                                            />
                                          </div>
                                        </Col>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {["Tags"].includes(filter.filter_by) ? (
                                      <>
                                        <Col xs={12} md={2}>
                                          <label htmlFor="filter[idx]">
                                            Tag Name{" "}
                                          </label>
                                          <Select
                                            styles={{
                                              multiValueRemove: (base) => ({
                                                ...base,
                                                display: readOnly ? "none" : "",
                                              }),
                                              multiValue: (styles) => {
                                                return {
                                                  ...styles,
                                                  backgroundColor: "#2AA9BE",
                                                  color: "white",
                                                };
                                              },
                                              multiValueLabel: (styles) => ({
                                                ...styles,
                                                color: "white",
                                                fontWeight: "bold",
                                              }),
                                            }}
                                            onChange={this.onChangeFilter.bind(
                                              this,
                                              idx,
                                              "tag_name_filter"
                                            )}
                                            isMulti
                                            options={select_items_tag}
                                            defaultValue={select_items_tag.filter(
                                              (e) =>
                                                e.label ===
                                                filter.tag_name_filter
                                            )}
                                            ref={(ref) =>
                                              (this.refTagName = ref)
                                            }
                                          />
                                        </Col>
                                        <Col xs={12} md={2}>
                                          <label htmlFor="filter[idx]">
                                            Tag Details{" "}
                                          </label>
                                          <Select
                                            styles={{
                                              multiValueRemove: (base) => ({
                                                ...base,
                                                display: readOnly ? "none" : "",
                                              }),
                                              multiValue: (styles) => {
                                                return {
                                                  ...styles,
                                                  backgroundColor: "#2AA9BE",
                                                  color: "white",
                                                };
                                              },
                                              multiValueLabel: (styles) => ({
                                                ...styles,
                                                color: "white",
                                                fontWeight: "bold",
                                              }),
                                            }}
                                            onChange={this.onChangeFilter.bind(
                                              this,
                                              idx,
                                              "tag_details_filter"
                                            )}
                                            isMulti
                                            options={
                                              select_items_tag_detail_filter
                                            }
                                            defaultValue={filter.tag_details_filter.filter(
                                              (item) =>
                                                details.some(
                                                  (det) => det.label == item
                                                )
                                            )}
                                            ref={(ref) =>
                                              (this.refTagDetails = ref)
                                            }
                                          />
                                        </Col>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {[
                                      "Nama",
                                      "Kota",
                                      "Pekerjaan",
                                      "Email",
                                      "Provinsi",
                                      "Whatsapp",
                                      "Alamat",
                                      "Jenis_Kelamin",
                                    ].includes(filter.filter_by) ? (
                                      <Col xs={12} md={2}>
                                        <label htmlFor="filter[idx]">
                                          Keyword{" "}
                                        </label>
                                        <div className="form-group">
                                          <input
                                            className="form-control form-control-sm"
                                            name="search"
                                            value={filter.search || ""}
                                            placeholder="Search ..."
                                            onKeyPress={(e) => {
                                              e.key === "Enter" &&
                                                e.preventDefault();
                                            }}
                                            onChange={(any) =>
                                              this.onChangeFilter(
                                                idx,
                                                "search",
                                                any.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )}
                                  </div>,
                                ];
                              })}
                            </Row>

                            <Row className="d-flex flex-row mt-1 ml-1 w-25">
                              <Button
                                variant="outline-info"
                                onClick={this.addFilter}
                                className="mr-2"
                                size="sm"
                              >
                                <i className="fas fa-plus" /> Add Filter
                              </Button>

                              <Button
                                variant="info"
                                onClick={this.handleSearch}
                                size="sm"
                              >
                                <i className="fas fa-search" /> Filter
                              </Button>
                            </Row>
                          </div>

                          <br></br>
                          {!isLoading ? (
                            <RemoteAll
                              data={list_datatable}
                              columns={columns}
                              defaultSorted={defaultSorted}
                              cellEditProps={cellEditProps}
                              page={page}
                              sizePerPage={sizePerPage}
                              totalSize={totalSize}
                              onTableChange={this.handleTableChange.bind(this)}
                            />
                          ) : (
                            <div className="d-flex align-self-center m-5">
                              <Spinner
                                className="text-center"
                                animation="border"
                                variant="primary"
                                size="lg"
                              />
                              <h5 className="ml-2">Loading Data</h5>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(UploadSingle);
