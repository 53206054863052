import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class UploadRawService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + "upload_raw", payload, {
      headers: authHeader(),
    });
  }

  createSchedule(payload) {
    return axios.post(API_URL_DEFAULT + "upload_raw/schedule", payload, {
      headers: authHeader(),
    });
  }

  readTags(query) {
    return axios.get(API_URL_DEFAULT + "upload_raw/?" + query, {
      headers: authHeader(),
    });
  }

  edit(payload) {
    return axios.put(API_URL_DEFAULT + "upload_raw", payload, {
      headers: authHeader(),
    });
  }

  delete(payload) {
    return axios.delete(API_URL_DEFAULT + "upload_raw", {
      data: payload,
      headers: authHeader(),
    });
  }
}

export default new UploadRawService();
