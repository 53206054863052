import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class ClustersService {
  readClusterSearch(query) {
    return axios.get(API_URL_DEFAULT + "clusters/?" + query, {
      headers: authHeader(),
    });
  }
}

export default new ClustersService();
