import React, { Component } from "react";
import RemoteTable from "../components/RemoteTable";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import RemoteAll from "../components/RemoteAll";

import { addTags, editTags, readTags, deleteTags } from "../modules/TagsModule";

import debug from "debug";
import TagDetails from "../components/TagDetails";

class Tags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      submitted_create: false,
      showModal: false,
      showModalDailySpotPrice: false,
      loading: false,
      disableButtonNotification: false,
      seconds: 60,
      readOnly: false,
      currencyreadOnly: false,
      unitreadOnly: false,
      isClearable: true,
      collection: {},
      list_datatable: [],
      errors: {},
      errors_edit: {},
      id: null,
      requester: null,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      search: "",
      production_grade: null,
      read_type: "",
      formula: null,
      select_items_material: [],
      select_items_material_raw: [],
      material_code: null,
      data_formula: [
        {
          material_raw_code: "",
          material_raw_description: "",
          qty: "",
        },
      ],
      data_formula_update: [
        {
          material_raw_code: "",
          material_raw_description: "",
          qty: "",
        },
      ],
      columns_formula: [
        {
          text: "Material",
          type: "select",
        },
        {
          text: "Qty",
          type: "numeric",
          val: "qty",
        },
        {
          text: "Action",
          type: "del",
        },
      ],
      columns: [
        {
          dataField: "tag_name",
          text: "Tag Name",
          classes: "text-center",
          sort: true,
        },

        {
          dataField: "updated_at",
          text: "Last Updated At",
          classes: "text-center",
          formatter: this.GetDateFormat,
          sort: true,
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ],
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "updated_at",
      sortOrder: "desc",
      defaultSorted: [
        {
          dataField: "updated-at",
          order: "desc",
        },
      ],
      cellEditProps: {},
      filter_by: null,
      errors_search: {},
      isSearch: false,
      query_material: `sortBy=name&sortOrder=asc&page=1&sizePerPage=10000&constraint=material_type&constraint_value=ZSFG&search=powder`,
      tag_name: null,
      tag_name_edit: null,
      validated: false,
      details: [""],
      details_edit: [""],
      submitted_edit: false,
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    const { sortBy, sortOrder, page, sizePerPage, query_material } = this.state;
    const query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;

    readTags(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }

    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        requester: user.username,
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };

  onChangeDetail = (any, idx, val) => {
    const details = this.state[any];
    let temp = [...details];

    temp[idx] = val;
    this.setState({
      [any]: [...temp],
    });
  };

  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.label,
        [name + "_code"]: newValue.value,
      });
      if (name == "material") {
        this.setState({
          isValidMaterial: true,
        });
      } else if (name == "customer") {
        this.setState({
          isValidCustomer: true,
        });
      } else if (name == "customer_create") {
        this.setState({
          isValidCustomerCreate: true,
        });
      }
    } else {
      this.setState({
        [name]: null,
        [name + "_code"]: null,
      });
      if (name == "material") {
        this.setState({
          isValidMaterial: false,
        });
      } else if (name == "customer") {
        this.setState({
          isValidCustomer: false,
        });
      } else if (name == "material_create") {
        this.setState({
          isValidMaterialCreate: false,
        });
      } else if (name == "customer_create") {
        this.setState({
          isValidCustomerCreate: false,
        });
      }
    }
  };

  //date format on datatable
  GetDateFormat = (cell, row) => {
    const date_formatted = dateFormat(new Date(row.updated_at), "dd mmmm yyyy");
    return <div>{date_formatted}</div>;
  };
  //action button
  GetActionFormat = (cell, row) => {
    const { isEdit, isDelete } = this.state;
    return (
      <div>
        {isEdit && (
          <button
            key={row._id}
            type="button"
            className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            onClick={() => this.toDetail(row)}
          >
            <i className="fas fa-edit" key={row._id}></i>
          </button>
        )}

        <button
          key={row._id}
          type="button"
          className="btn btn-success btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => this.toView(row)}
        >
          <i className="fas fa-eye" key={row._id}></i>
        </button>

        {isDelete && (
          <button
            type="button"
            className="btn btn-danger btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            data-id={row._id}
            onClick={() => this.deleteData(row._id)}
          >
            <i className="fas fa-trash"></i>
          </button>
        )}
      </div>
    );
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    return <div>{isNaN(cell) ? "" : thousandformat(cell, ".", ".", ",")}</div>;
  };

  deleteData = (id) => {
    const { history } = this.props;
    const { requester } = this.state;
    let collection = { _id: id, requester: requester };
    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, cancel entries",
      }).then((result) => {
        if (result.value) {
          deleteTags(collection)
            .then((response) => {
              toast.success("Data has been cancelled successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-salesforecast-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been cancelled successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-salesforecast-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Information", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  toDetail = (row) => {
    this.setState(
      {
        id: row._id,
        tag_name_edit: row.tag_name,
        details_edit: [...row.details],
      },
      () => {
        this.toggle();
      }
    );
  };

  toView = (row) => {
    this.setState(
      {
        id: row._id,
        tag_name_edit: row.tag_name,
        details_edit: [...row.details],
        readOnly: true,
      },
      () => {
        this.toggle();
      }
    );
  };

  //toggle modal

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  //on modal hide

  onModalHide = () => {
    this.setState({
      id: null,
      submitted_edit: false,

      showModal: !this.state.showModal,
      readOnly: false,
    });
  };
  //validate input
  validateForm = () => {
    let { tag_name, details, errors } = this.state;

    if (!tag_name) {
      errors.tag_name = "This is field is required";
    } else {
      delete errors.tag_name;
    }
    return errors;
  };
  //validate input
  validateFormEdit = () => {
    let { tag_name_edit, details_edit, errors_edit } = this.state;

    if (!tag_name_edit) {
      errors_edit.tag_name_edit = "This is field is required";
    } else {
      delete errors_edit.tag_name_edit;
    }

    if (details_edit.length > 0) {
      details_edit.forEach((item, idx) => {
        if (item == "") {
          errors_edit[`detail_${idx}`] = "This is field is required";
        } else {
          delete errors_edit[`detail_${idx}`];
        }
      });
    }
    return errors_edit;
  };
  validateFormCreate = (production_grade) => {
    let errors = this.state.errors_create;
    if (!production_grade) {
      errors.production_grade = "This is field required";
      this.setState({
        isValidProductionGrade: false,
      });
    }

    return errors;
  };

  validateSearch = (filter_by, search) => {
    let errors = this.state.errors_search;
    if (!filter_by) {
      errors.filter_by = "This is field required";
    }
    if (!search) {
      errors.search = "This is field required";
    }

    return errors;
  };
  //get data from backend
  reloadDataBackend = () => {
    const { sortBy, sortOrder, page, sizePerPage, search } = this.state;
    const query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;

    readTags(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
        tag_name: null,
        details: [""],
        tag_name_edit: null,
        details_edit: [""],
      });
    });
  };

  handleTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
    if (page == 0) {
      page = 1;
    }
    const { search } = this.state;
    this.setState({
      sizePerPage: sizePerPage,
      page: page,
      sortBy: sortField,
      sortOrder: sortOrder,
    });

    const { sortBy } = this.state;
    const query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;

    readTags(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });
  };

  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const { history } = this.props;
    const { requester, tag_name, details, errors } = this.state;

    const user = JSON.parse(localStorage.getItem("user"));
    let department = user.details.hris_org_tree.current_person.nama_department;

    if (!this.validateForm()) {
      return;
    }

    let collection = {
      tag_name: tag_name,
      details: details,

      department: department,
      requester: requester,
      write_type: "overwrite",
    };
    if (requester && tag_name && Object.keys(errors).length == 0) {
      //check if tag exists
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          addTags(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                //read data exchange rate on backend
                this.setState({
                  submitted: false,
                });
                this.reloadDataBackend();
                history.push("/master-data/tags");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-failed",
              });
              if (err.includes("overwrite")) {
                let msg = err.split(".");
                Swal.fire({
                  title: `${msg[0]}!`,
                  text: `${msg[1]}`,
                  icon: "info",
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                }).then((result) => {
                  if (result.value) {
                    collection.accept_write = true;
                    addTags(collection)
                      .then((response) => {
                        toast.success("Data has been saved successfully", {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          toastId: "customId-add-daily-spot-price-success",
                        });
                        Swal.fire({
                          title: "Information",
                          icon: "success",
                          text: "Data has been saved successfully",
                          showConfirmButton: false,
                          timer: 2000,
                        }).then((result) => {
                          //read data exchange rate on backend
                          this.setState({
                            submitted: false,
                          });
                          this.reloadDataBackend();
                          history.push("/master-data/tags");
                        });
                      })
                      .catch((err) => {
                        toast.error(err, {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          toastId: "customId-add-daily-spot-price-failed",
                        });
                      });
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire("Cancelled", "Your data is safe :)", "error");
                  }
                });
              }
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      Swal.fire({
        title: "Not Allowed!",
        text: "Please check your input !",
        icon: "info",
      });
    }
  };
  //handle submit edit
  handleSubmitEdit = () => {
    this.setState({ submitted_edit: true });

    //get department
    const user = JSON.parse(localStorage.getItem("user"));
    let department = user.details.hris_org_tree.current_person.nama_department;

    if (!this.validateFormEdit()) {
      return;
    }

    const { id, tag_name_edit, details_edit, requester, errors_edit } =
      this.state;

    let collection = {
      _id: id,
      tag_name: tag_name_edit,
      details: details_edit,
      department: department,
      updated_by: requester,
    };
    if (id && tag_name_edit && Object.keys(errors_edit).length == 0) {
      Swal.fire({
        title: "Are you sure to update?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, update entries",
      }).then((result) => {
        if (result.value) {
          editTags(collection)
            .then((response) => {
              this.onModalHide();
              this.setState({
                submitted_edit: false,
              });
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-update-daily-spot-price-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-update-daily-spot-price-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      Swal.fire({
        title: "Not Allowed!",
        text: "Please check your entries",
        icon: "info",
      });
    }
  };
  //handle search
  handleSearch = () => {
    const { search, page, sizePerPage, sortBy, sortOrder } = this.state;
    const query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}&search=${search}`;

    readTags(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });
  };

  handleAddDetail = (any) => {
    const details = this.state[any];
    let temp = [...details];
    temp.unshift("");
    this.setState({
      [any]: [...temp],
    });
  };

  handleRemoveDetail = (any, idx) => {
    const details = this.state[any];
    if (details.length != 1) {
      let temp = [...details];
      temp.splice(idx, 1);
      this.setState(
        {
          [any]: [...temp],
          [any.includes("edit") ? "errors_edit" : "errors"]: {},
        },
        () => {
          any.includes("edit") ? this.validateFormEdit() : this.validateForm();
        }
      );
    }
  };

  render() {
    let {
      disableButtonNotification,
      columns,
      defaultSorted,
      showModal,
      cellEditProps,
      list_datatable,
      id,
      qty,
      production_grade,
      formula,
      errors,
      errors_create,
      submitted_create,
      submitted,
      loading,
      isValidMaterial,
      isCreate,
      isRead,
      readOnly,
      isEdit,
      isDelete,
      search,
      page,
      sizePerPage,
      totalSize,
      filter_by,
      isSearch,
      select_items_material,
      material_code,
      read_type,
      data_formula,
      columns_formula,
      data_formula_update,
      select_items_material_raw,
      isClearable,
      tag_name,
      details,
      validated,
      submitted_edit,
      tag_name_edit,
      details_edit,
      errors_edit,
    } = this.state;
    const customStylesMaterial = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterial
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterial
            ? "#ddd"
            : "green",
        },
      }),
    };
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>{readOnly ? "Detail" : "Update"} Tags</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <form
                autoComplete="off"
                className={
                  submitted_edit
                    ? "needs-validation was-validated"
                    : "needs-validation"
                }
                noValidate
                ref={(form) => (this.form_edit = form)}
              >
                <Row>
                  <Col xs={12} md={4}>
                    <div className="form-group">
                      <label htmlFor="tags">Tag Name</label>
                      <input
                        type="text"
                        className="form-control form-control"
                        name="tag_name_edit"
                        placeholder="Tag Name"
                        required="required"
                        value={tag_name_edit || ""}
                        onChange={this.onChangeValue}
                        min="0"
                        readOnly={readOnly}
                      />
                      {errors_edit.tag_name && (
                        <div className="invalid-feedback">
                          {errors_edit.tag_name}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <TagDetails
                  details={details_edit}
                  readOnly={readOnly}
                  onRemove={(type, idx) => this.handleRemoveDetail(type, idx)}
                  onChange={(type, idx, value) => {
                    this.onChangeDetail(type, idx, value);
                  }}
                  onAdd={(type) => {
                    this.handleAddDetail(type);
                  }}
                  isEdit={true}
                />
              </form>
            </ModalBody>
            <ModalFooter>
              {!readOnly && (
                <Button
                  variant="success"
                  onClick={this.handleSubmitEdit}
                  className="float-right"
                >
                  <i className="fas fa-save" /> Update
                </Button>
              )}
            </ModalFooter>
          </Modal>
          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h1 className="card-title">Tags</h1>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <form
                            autoComplete="off"
                            className={
                              submitted
                                ? "needs-validation was-validated"
                                : "needs-validation"
                            }
                            noValidate
                            ref={(form) => (this.form = form)}
                          >
                            <Row>
                              <Col xs={12} md={4}>
                                <div className="form-group">
                                  <label htmlFor="tags">
                                    Tag Name{" "}
                                    <label style={{ color: "red" }}>*</label>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control form-control"
                                    name="tag_name"
                                    placeholder="Tag Name"
                                    required="required"
                                    value={tag_name || ""}
                                    onChange={this.onChangeValue}
                                    min="0"
                                  />
                                  {errors.tag_name && (
                                    <div className="invalid-feedback">
                                      {errors.tag_name}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <TagDetails
                              details={details}
                              readOnly={readOnly}
                              errors={errors}
                              onRemove={(type, idx, value) =>
                                this.handleRemoveDetail(type, idx)
                              }
                              onChange={(type, idx, value) => {
                                this.onChangeDetail(type, idx, value);
                              }}
                              onAdd={(type) => {
                                this.handleAddDetail(type);
                              }}
                              isEdit={false}
                            />

                            <Row>
                              <Col xs={12} md={12}>
                                <Button
                                  variant="success"
                                  onClick={this.handleSubmit}
                                  className="float-right"
                                >
                                  <i className="fas fa-save" /> Submit
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              <div className="container-fluid">
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h1 className="card-title">Historical Data</h1>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <Row>
                            <Col xs={12} md={2}>
                              <div className="form-group">
                                <input
                                  className="form-control form-control-sm"
                                  name="search"
                                  value={search || ""}
                                  placeholder="Tag Name"
                                  onKeyPress={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                  }}
                                  onChange={this.onChangeValue}
                                />
                              </div>
                            </Col>
                            <Col xs={12} md={2}>
                              <Button
                                variant="info"
                                onClick={this.handleSearch}
                                className="float-left"
                              >
                                <i className="fas fa-search" /> Search
                              </Button>
                            </Col>
                          </Row>
                          <br />
                          <RemoteAll
                            data={list_datatable}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            cellEditProps={cellEditProps}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            onTableChange={this.handleTableChange.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(Tags);
