import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class TagsService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + "tags", payload, {
      headers: authHeader(),
    });
  }
  readTags(query) {
    return axios.get(API_URL_DEFAULT + "tags/?" + query, {
      headers: authHeader(),
    });
  }
  readSelectTags() {
    return axios.get(API_URL_DEFAULT + "tags/select", {
      headers: authHeader(),
    });
  }

  edit(payload) {
    return axios.put(API_URL_DEFAULT + "tags", payload, {
      headers: authHeader(),
    });
  }
  delete(payload) {
    return axios.delete(API_URL_DEFAULT + "tags", {
      data: payload,
      headers: authHeader(),
    });
  }
}

export default new TagsService();
