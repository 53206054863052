import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import PropTypes from "prop-types";
import filterFactory, { Comparator } from "react-bootstrap-table2-filter";
import cellEditFactory from "react-bootstrap-table2-editor";
import Button from "react-bootstrap/Button";

const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
  <li
    key={text}
    role="presentation"
    className="dropdown-item"
    onClick={(e) => {
      e.preventDefault();
      onSizePerPageChange(page);
    }}
  >
    <Button
      variant="default"
      style={{ backgroundColor: "transparent", borderColor: "white" }}
    >
      {text}
    </Button>
  </li>
);
const RemoteAll = ({
  data,
  columns,
  defaultSorted,
  page,
  sizePerPage,
  totalSize,
  cellEditProps,
  onTableChange,
}) => (
  <div>
    <BootstrapTable
      bootstrap4
      remote={{
        filter: true,
        pagination: true,
        sort: true,
        cellEdit: false,
      }}
      keyField="_id"
      data={data}
      columns={columns}
      defaultSorted={defaultSorted}
      filter={filterFactory()}
      pagination={paginationFactory({
        page,
        sizePerPage,
        totalSize,
        sizePerPageOptionRenderer,
      })}
      headerClasses="text-center"
      bodyClasses="text-left"
      onTableChange={onTableChange}
      cellEdit={cellEditFactory(cellEditProps)}
      noDataIndication={() => (<div>No Data</div>)}
    />
  </div>
);

RemoteAll.propTypes = {
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired,
}; 

export default RemoteAll;
