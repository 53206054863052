import React, { Component } from "react";
import Content from "../components/templates/Content";
import Footer from "../components/templates/Footer";
import NavSideLayout from "../components/NavSideLayout";

export default class Dashboard extends Component {
  render() {
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <NavSideLayout />
          <Content />
          <Footer />
        </div>
      </div>
    );
  }
}
