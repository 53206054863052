import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class DashboardService {
  read(query) {
    return axios.get(API_URL_DEFAULT + "dashboard/?" + query, {
      headers: authHeader(),
    });
  }
}

export default new DashboardService();
